import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_GLUECKSPIRALE } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';
import { spiel77 } from '@/config/game/spiel77';
import { gameStepsGluecksspirale } from '@/config/game/steps';
import { super6 } from '@/config/game/super6';

export const gluecksSpirale: GameConfig = {
  id: ID_GLUECKSPIRALE,
  additionalGameId: 4,
  shortname: 'gluecksspirale',
  title: 'GlücksSpirale',
  color: '#000000',
  logo: LogoVariant.GluecksSpirale,
  logoBlack: LogoVariant.GluecksSpiraleColor,
  logoColor: LogoVariant.GluecksSpiraleColor,
  button: ButtonVariant.Gluecksspirale,
  buttonSecondary: ButtonVariant.GluecksspiraleSecondary,
  jackpotLabelSuffix: 'Monatlich',
  jackpotDesc: '10.000 €',
  jackpotDescAlt: 'o.&nbsp;2,1&nbsp; Mio.&nbsp;€ sofort',
  jackpotTimes: 'Monatl., 20 Jahre lang',
  chanceDesc: 'Chance 1:10 Mio., Kl. 7. Ab 18 J.',
  basePrice: 5,
  playNumber: {
    amountNumbers: 7,
    superNumberIndex: -1,
    valueMin: 0,
    valueMax: 9,
    noHide: true,
  },
  weeksNumber: [
    { value: 1, label: '1 Woche' },
    { value: 2, label: '2 Wochen' },
    { value: 3, label: '3 Wochen' },
    { value: 4, label: '4 Wochen' },
    { value: 5, label: '5 Wochen' },
  ],
  weeklySubscription: true,
  apiUrls: {
    winningNumbers: API.stats.gluecksspiraleLast,
    winningNumbersDate: API.stats.gluecksspiraleDraw,
  },
  drawDays: {
    options: [],
    initial: ['Samstag'],
    dynamic: true,
  },

  drawDeadlines: [
    {
      day: 'Samstag',
      time: '19:00',
    },
  ],
  additionalGames: [spiel77, super6],
  steps: gameStepsGluecksspirale,
  urls: {
    overview: '/gluecksspirale',
    stats: '/gluecksspirale/gewinnzahlen',
    game: '/gluecksspirale/spielen',
  },
  label: {
    text: 'GlücksSpirale',
    imgPath: 'logo-gluecksspirale-color.svg',
  },
  data: {
    price: 5,
    dependsOnDrawDays: false,
  },
  deadlineCols: [
    {
      head: 'Bundesland',
      key: 'state',
    },
    {
      head: 'Samstag',
      key: 'gluecksspiraleClosingDate',
    },
  ],
  digitAmount: 7,
  queryParamKey: 'gs',
  info: {
    playNumber: {
      title: 'So tippen Sie die GlücksSpirale',
      desc:
        'Die Losnummer für die GlücksSpirale können Sie einfach ' +
        'per Eingabe von Nummern ändern oder per Zufall eine neue Nummer generieren. ' +
        'Mit der Losnummer definieren Sie auch die Nummern für die Zusatzlotterien Spiel 77 und SUPER 6.',
    },
  },
};
