import Link from 'next/link';

export default function LinkWrapper({
  href,
  tabIndex,
  children,
  title = '',
  ariaLabel = '',
  ...rest
}) {
  const linkTitle = () => {
    return href.startsWith('mailto:')
      ? `Email an: ${href.replace('mailto:', '')}`
      : title;
  };
  return (
    <Link
      href={href}
      title={linkTitle()}
      aria-label={ariaLabel}
      {...rest}
      prefetch={false}
      tabIndex={tabIndex}
      target={
        href.startsWith('mailto:')
          ? '_top'
          : href.startsWith('http')
          ? '_blank'
          : undefined
      }
    >
      {children}
    </Link>
  );
}
