import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_TOTO_EW } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';

export const totoew: GameConfig = {
  id: ID_TOTO_EW,
  shortname: 'toto',
  title: 'TOTO 13er Ergebniswette',
  logo: LogoVariant.TotoEW,
  button: ButtonVariant.Primary,
  buttonSecondary: ButtonVariant.Secondary,
  drawDeadlines: [
    {
      day: 'Samstag',
      time: '13:00',
    },
  ],
  jackpotDesc: 'wird ermittelt',
  jackpotOfflineDesc: 'wird ermittelt',
  jackpotSrc: API.stats.totoewShortinfo,
  jackpotTimes: 'Diesen Samstag im Jackpot',
  jackpotIsEurUnFormatted: true,
  chanceDesc: 'Chance 1:1,6 Mio., Kl. 1. Ab 18 J.',
  steps: [],
  urls: {
    stats: '/ueber/toto-fussballwette/toto-gewinnzahlen-und-quoten',
    game: '/ueber/toto-fussballwette',
  },
  apiUrls: {
    shortinfo: API.stats.totoewShortinfo,
    winningNumbers: API.stats.totoewLast,
    winningNumbersDate: API.stats.totoewDraw,
  },
};
