export enum ButtonVariant {
  Back = 'Button--back',
  ContentBlock = 'Button--content_block',
  Primary = 'Button--primary',
  Lotto6aus49 = 'Button--lotto6aus49',
  Lotto6aus49Secondary = 'Button--lotto6aus49-secondary',
  Keno = 'Button--keno',
  KenoSecondary = 'Button--keno-secondary',
  Gluecksspirale = 'Button--gluecksspirale',
  GluecksspiraleSecondary = 'Button--gluecksspirale-secondary',
  Eurojackpot = 'Button--eurojackpot',
  EurojackpotSecondary = 'Button--eurojackpot-secondary',
  Rubbellose = 'Button--rubbellose',
  QuickTip = 'Button--quick_tip',
  Secondary = 'Button--secondary',
  Square = 'Button--square',
  Stacked = 'Button--stacked',
  Standard = 'Button--standard',
  Text = 'Button--text',
  Loading = 'Button--loading',
  PrimaryIconRight = 'Button--primary-icon-right',
  PrimaryIconLeft = 'Button--primary-icon-left',
}
