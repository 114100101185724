import {
  LoadingProgress,
  LoadingProgressSize,
} from '@/components/LoadingProgress';
import { NotificationBanner } from '@/components/NotificationBanner';
import { Portal } from '@/components/Portal';
import React from 'react';

interface LoadingInfoProps {
  isOpen: boolean;
  isFinished: boolean;
  onClick: () => void;
}
export class LoadingInfo extends React.Component<LoadingInfoProps> {
  render() {
    const { isOpen, isFinished, onClick } = this.props;
    return (
      <Portal selector="#NotificationBannerPortal">
        <NotificationBanner
          isOpen={isOpen}
          title={
            isFinished ? 'App erfolgreich installiert' : 'App wird installiert'
          }
          text={
            isFinished
              ? 'Sie können nun viele Funktionen der LOTTO.de offline nutzen.'
              : 'Benötigte Daten zur Offline-Verwendung werden heruntergeladen.'
          }
          icon={
            <LoadingProgress
              size={LoadingProgressSize.Big}
              visible={isOpen}
              finished={isFinished}
            />
          }
          onClose={onClick}
        />
      </Portal>
    );
  }
}
