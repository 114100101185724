export enum DataLayerEvent {
  UserClick = 'user_click',
  PassedTicket = 'spielschein_abgegeben',
  VirtualPageview = 'virtualPageview',
}

const pushDatalayer = (
  action: string,
  name: string | {},
  event?: DataLayerEvent
) => {
  if (typeof window !== 'undefined') {
    (window as any).dataLayer = (window as any).dataLayer || [];
    let dataLayerObject = { [`${action}`]: name };
    if (event) {
      dataLayerObject = { event, [`${action}`]: name };
    }
    (window as any).dataLayer.push(dataLayerObject);
  }
};

export const pushGameToDatalayer = (data: any[], event?: DataLayerEvent) => {
  if (typeof window !== 'undefined') {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event,
      Spiel: data[0],
      'Anzahl der Spielfelder': data[1],
      Mittwoch: data[2],
      Samstag: data[3],
      MittwochSamstag: data[4],
      'Anzahl der Wochen': data[5],
      Dauerspiel: data[6],
      Account: data[7],
      'Spiel 77': data[8],
      'Spiel 6': data[9],
      SuperZahl: data[10],
      Glücksspirale: data[11],
    });
  }
};

export default pushDatalayer;
