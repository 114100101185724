import { Icon, IconVariant } from '@/components/Icon';
import classnames from 'classnames';

export enum LoadingProgressSize {
  Big = 'LoadingProgress--big',
  Small = 'LoadingProgress--small',
}

export const LoadingProgress = ({
  visible = false,
  size,
  finished,
  onClick,
}: {
  visible?: boolean;
  size: LoadingProgressSize;
  finished?: boolean;
  onClick?: () => void;
}) => (
  <div
    style={{ opacity: 0 }}
    className={classnames('LoadingProgress', size, {
      'LoadingProgress--visible': visible,
      'LoadingProgress--finished': finished,
    })}
    onClick={() => onClick && onClick()}
  >
    <div
      className={classnames('LoadingProgress__rotatingCircle', {
        'LoadingProgress__rotatingCircle--finished': finished,
      })}
    />

    <div className="LoadingProgress__iconContainer">
      <Icon
        variant={IconVariant.Checkmark}
        className={classnames('LoadingProgress__icon', {
          'LoadingProgress__icon--hidden': !finished,
        })}
      />
      <Icon
        variant={IconVariant.ArrowDown}
        className={classnames('LoadingProgress__icon', {
          'LoadingProgress__icon--hidden': finished,
        })}
      />
    </div>
  </div>
);
