import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { gluecksSpirale } from '@/config/game/gluecksSpirale';
import { ID_LOTTO6AUS49 } from '@/config/game/ids';
import { GameConfig, WeeksNumberConfig } from '@/config/game/index';
import { spiel77 } from '@/config/game/spiel77';
import { gameStepsDefault, gameStepsLottoB } from '@/config/game/steps';
import { super6 } from '@/config/game/super6';

export const lotto6aus49: GameConfig = {
  id: ID_LOTTO6AUS49,
  shortname: 'lotto6aus49',
  title: 'LOTTO 6aus49',
  logo: LogoVariant.Lotto6aus49,
  button: ButtonVariant.Lotto6aus49,
  buttonSecondary: ButtonVariant.Lotto6aus49Secondary,
  imgPath: 'logo-lotto6aus49.svg',
  jackpotSrc: API.stats.lottoShortinfo,
  jackpotLast: API.stats.lottoLast,
  jackpotDesc: 'wird ermittelt',
  jackpotDescGame: 'Der Klassiker',
  jackpotOfflineDesc: 'wird ermittelt',
  jackpotOfflineDescGame: 'Der Klassiker',
  jackpotTimes: 'Der nächste Jackpot',
  chanceDesc: 'Chance 1:140 Mio., Kl. 1. Ab 18 J.',
  couponGroup: {
    width: 280,
    columns: 4,
    amountCoupons: 12,
    couponPrice: getCouponPrice(),
    quickTips: [1, 2, 4, 6, 8],
    numberGroups: [
      {
        amountNumbers: 49,
        startValue: 1,
        minCrossed: 6,
        maxCrossed: 6,
        columns: 7,
      },
    ],
  },
  playNumber: {
    amountNumbers: 7,
    superNumberIndex: 6,
    valueMin: 0,
    valueMax: 9,
  },
  drawDays: {
    options: getWeekOptions(),
    initial: ['Mittwoch'],
    dynamic: true,
  },
  drawDeadlines: [
    {
      day: 'Mittwoch',
      time: '18:00',
    },
    {
      day: 'Samstag',
      time: '19:00',
    },
  ],
  weeksNumber: getWeeksForCurrentDate(),
  weeklySubscription: getWeeklySubscription(),
  additionalGames: [spiel77, super6, gluecksSpirale],
  steps: gameStepsDefault,
  variantSteps: gameStepsLottoB,
  apiUrls: {
    shortinfo: API.stats.lottoShortinfo,
    statistics: `${API.stats.lottoStatistics}/0/0`,
    winningNumbers: [
      `${API.stats.lottoLastGame}/1`,
      `${API.stats.lottoLastGame}/2`,
    ],
    winningNumbersDate: API.stats.lottoDraws,
  },
  deadlineCols: [
    {
      head: 'Bundesland',
      key: 'state',
    },
    {
      head: 'Mittwoch',
      key: 'lottoClosingDateWednesday',
    },
    {
      head: 'Samstag',
      key: 'lottoClosingDateSaturday',
    },
  ],
  urls: {
    overview: '/lotto-6aus49',
    stats: '/lotto-6aus49/lottozahlen',
    game: '/lotto-6aus49/spielen',
    live: '/lotto-6aus49/lotto-ziehung',
  },
  alternativeDesign: {
    productLogoHeader: LogoVariant.Lotto6aus49ProductRefresh,
  },
  info: {
    coupons: {
      title: 'So tippen Sie LOTTO 6aus49',
      desc:
        'Auf einem LOTTO-Schein befinden sich 12 Tippfelder mit jeweils 49 Zahlen. ' +
        'In einem Tippfeld müssen Sie 6 Zahlen ankreuzen. Sie können ein Feld anklicken ' +
        'und die Zahlen selbst ankreuzen oder Sie nutzen bequem unsere Quick-Tipps: damit können ' +
        'Sie ein oder mehrere Tippfelder schnell und bequem per ' +
        'Zufall ausfüllen.',
    },
    temporaryChanges: {
      active: getTemporaryChangesActive(),
      title: 'Vorübergehend eingeschränkte Auswahl',
      desc: '',
    },
    onlySaturday: {
      active: getOnlySaturdayActive(),
      title: 'Vorübergehend eingeschränkte Auswahl',
      desc: '',
    },
  },
};

function getWeeksForCurrentDate(): WeeksNumberConfig[] {
  return [
    { value: 1, label: '1 Woche' },
    { value: 2, label: '2 Wochen' },
    { value: 3, label: '3 Wochen' },
    { value: 4, label: '4 Wochen' },
    { value: 5, label: '5 Wochen' },
  ];
}

function getWeeklySubscription(): boolean {
  return true;
}

function getWeekOptions(): string[][] {
  return [['Mittwoch'], ['Samstag'], ['Mittwoch', 'Samstag']];
}

function getCouponPrice(): number {
  return 1.2;
}

function getTemporaryChangesActive(): boolean {
  return false;
}

function getOnlySaturdayActive(): boolean {
  return false;
}
