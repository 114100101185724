export default function replaceAbbreviations(text: string) {
  return {
    __html: text
      .replace(/Abs\./g, '<abbr title="Absatz">Abs.</abbr>')
      .replace(
        /ARD/g,
        '<abbr title="Arbeitsgemeinschaft der öffentlich-rechtlichen Rundfunkanstalten der Bundesrepublik Deutschland">ARD</abbr>'
      )
      .replace(/Art\./g, '<abbr title="Artikel">Art.</abbr>')
      .replace(/BSL/g, '<abbr title="Bundesweite Sofortlotterien">BSL</abbr>')
      .replace(/CDN/g, '<abbr title="Content Delivery Network">CDN</abbr>')
      .replace(
        /Co\. OHG/g,
        '<abbr title="Compagnie offene Handelsgesellschaft">Co. OHG</abbr>'
      )
      .replace(
        /Co\. KG/g,
        '<abbr title="Compagnie Kommanditgesellschaft">Co. KG</abbr>'
      )
      .replace(/DM\s/g, '<abbr title="Deutsche Mark">DM</abbr> ')
      .replace(
        /DS-GVO/g,
        '<abbr title="Datenschutz-Grundverordnung">DS-GVO</abbr>'
      )
      .replace(
        /DLTB/g,
        '<abbr title="Deutscher Lotto- und Totoblock">DLTB</abbr>'
      )
      .replace(
        /DISQ/g,
        '<abbr title="Deutsches Institut für Servicequalität">DISQ</abbr>'
      )
      .replace(/Mo\./g, '<abbr title="Montag">Mo.</abbr>')
      .replace(/Di\./g, '<abbr title="Dienstag">Di.</abbr>')
      .replace(/Mi\./g, '<abbr title="Mittwoch">Mi.</abbr>')
      .replace(/Do\./g, '<abbr title="Donnerstag">Do.</abbr>')
      .replace(/Fr\./g, '<abbr title="Freitag">Fr.</abbr>')
      .replace(/Sa\./g, '<abbr title="Samstag">Sa.</abbr>')
      .replace(/So\./g, '<abbr title="Sonntag">So.</abbr>')
      .replace(
        /DtGV/g,
        '<abbr title="Deutsche Gesellschaft für Verbraucherstudien">DtGV</abbr>'
      )
      .replace(/EU\s/g, '<abbr title="Europäische Union">EU</abbr> ')
      .replace(
        /GlüStV 2021/g,
        '<abbr title="Glücksspielstaatsvertrag 2021">GlüStV 2021</abbr>'
      )
      .replace(
        /GTE/g,
        '<abbr title="Gran Turismo Electric (Hybridantrieb)">GTE</abbr>'
      )
      .replace(
        /(?<!Co\.\s)GmbH/g,
        '<abbr title="Gesellschaft mit beschränkter Haftung">GmbH</abbr>'
      )
      .replace(/HRB/g, '<abbr title="Handelsregister Abteilung B">HRB</abbr>')
      .replace(/Inc\./g, '<abbr title="Incorporated">Inc.</abbr>')
      .replace(/iOS/g, '<abbr title="iPhone Operating System">iOS</abbr>')
      .replace(/J\./g, '<abbr title="Jahren">J.</abbr>')
      .replace(
        /(?<!Co\.\s)KG/g,
        '<abbr title="Kommanditgesellschaft">KG</abbr>'
      )
      .replace(/Kl\./g, '<abbr title="Klasse">Kl.</abbr>')
      .replace(/lit\./g, '<abbr title="littera">lit.</abbr>')
      .replace(/LLG/g, '<abbr title="Landeslotteriegesellschaft">LLG</abbr>')
      .replace(/Max\./g, '<abbr title="Maximum">Max.</abbr>')
      .replace(/Min\./g, '<abbr title="Minimum">Min.</abbr>')
      .replace(/Mio\./g, '<abbr title="Millionen">Mio.</abbr>')
      .replace(/Monatl\./g, '<abbr title="Monatlich">Monatl.</abbr>')
      .replace(/MStV/g, '<abbr title="Medienstaatsvertrag">MStV</abbr>')
      .replace(
        /(?<!G)mbH/g,
        '<abbr title="mit beschränkter Haftung">mbH</abbr>'
      )
      .replace(/ntv/g, '<abbr title="ntv Nachrichtenfernsehen GmbH">ntv</abbr>')
      .replace(/NRW/g, '<abbr title="Nordrhein-Westfalen">NRW</abbr>')
      .replace(
        /OASIS/g,
        '<abbr title="Online-Abfrage-Spieler-Informations-System">OASIS</abbr>'
      )
      .replace(
        /ODR/g,
        '<abbr title="Online dispute resolution / Online-Streitbeilegung">ODR</abbr>'
      )
      .replace(
        /(?<!Co\.\s)OHG/g,
        '<abbr title="offene Handelsgesellschaft">OHG</abbr>'
      )
      .replace(/(\?s\.)\so\./g, ` <abbr title="oder">o.</abbr>`)
      .replace(/^o\./, `<abbr title="oder">o.</abbr>`)
      .replace(
        /TÜV/,
        `<abbr title="Technischer Überwachungs-Verein">TÜV</abbr>`
      )
      .replace(
        /TüV/,
        `<abbr title="Technischer Überwachungs-Verein">TüV</abbr>`
      )
      .replace(/URL/g, '<abbr title="Uniform Resource Locator">URL</abbr>')
      .replace(/USA/g, '<abbr title="United States of America">USA</abbr>')
      .replace(/USt\./g, '<abbr title="Umsatzsteuer">USt.</abbr>')
      .replace(/usw\./g, '<abbr title="und so weiter">usw.</abbr>')
      .replace(/vgl./g, '<abbr title="vergleiche">vgl.</abbr>')
      .replace(
        /SEA/g,
        '<abbr title="Search Engine Advertising / Suchmaschinenwerbung">SEA</abbr>'
      )
      .replace(/\su\./g, ' <abbr title="und">u.</abbr>')
      .replace(/VOD/g, '<abbr title="Video on Demand">VOD</abbr>')
      .replace(/ZDF/g, '<abbr title="Zweites Deutsches Fernsehen">ZDF</abbr>')
      .replace(/z.B./g, '<abbr title="zum Beispiel">z.B.</abbr>')
      .replace(/z. B./g, '<abbr title="zum Beispiel">z. B.</abbr>')
      .replace(/zzgl./g, '<abbr title="zuzüglich">zzgl.</abbr>')
      .replace(/PKW/g, '<abbr title="Personenkraftwagen">PKW</abbr>')
      .replace(/Ref./g, '<abbr title="Referat">Ref.</abbr>')
      .replace(/SES/g, '<abbr title="Service Experience Score">SES</abbr>')
      .replace(/VW/g, '<abbr title="Volkswagen">VW</abbr>')
      .replace(/BMW/g, '<abbr title="Bayerische Motoren Werke">BMW</abbr>')
      .replace(
        /XXL/g,
        '<abbr title="extra extra large (äußerst groß)">XXL</abbr>'
      )
      .replace(
        /VDP/g,
        '<abbr title="Vulnerability Disclosure Policy">VDP</abbr>'
      ),
  };
}
