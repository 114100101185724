import moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import 'moment/locale/de';

moment.locale('de');

export enum DateFormat {
  Full = 'dddd DD.MM.YYYY',
  Default = 'DD.MM.YYYY',
  Dayname = 'dddd',
  ISODashed = 'YYYY-MM-DD',
  LongMonth = 'DD. MMMM YYYY',
  Extended = 'dddd[,] DD.MM.YYYY',
  ExtendedAlt = ' DD.MM. [(]dddd[)]',
  DateAndTime = 'DD.MM.YYYY HH:mm',
  DayAndTime = 'dddd HH:mm',
}

export function formatDate(
  timestamp: number | string | Date | moment.Moment,
  format: string
): string {
  //@ts-ignore
  return moment(timestamp).tz('Europe/Berlin').format(format);
}

export function getNow(): moment.Moment {
  //@ts-ignore
  return moment().tz('Europe/Berlin');
}

export function getYesterday(): moment.Moment {
  return getNow().subtract(1, 'days');
}

export function getTimeDiff(start: moment.Moment, end: moment.Moment) {
  const diff = moment.utc(end.diff(start)).format('HH:mm:ss').split(':');
  return {
    hours: diff[0],
    minutes: diff[1],
    seconds: diff[2],
  };
}

export function fromTimestamp(timestamp: number): moment.Moment {
  //@ts-ignore
  return moment(timestamp).tz('Europe/Berlin');
}

export function parse(input: string, format: string): moment.Moment {
  //@ts-ignore
  return moment(input, format).tz('Europe/Berlin');
}
