import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import { eurojackpot } from '@/config/game/eurojackpot';
import { gluecksSpirale } from '@/config/game/gluecksSpirale';
import { keno } from '@/config/game/keno';
import { lotto6aus49 } from '@/config/game/lotto6aus49';
import { plus5 } from '@/config/game/plus5';
import { spiel77 } from '@/config/game/spiel77';
import { GameStep } from '@/config/game/steps';
import { super6 } from '@/config/game/super6';
import { toto } from '@/config/game/toto';
import { totoaw } from '@/config/game/totoaw';
import { totoew } from '@/config/game/totoew';
import { rubbellose } from './rubbellose';

export const allGames: GameConfig[] = [
  lotto6aus49,
  eurojackpot,
  gluecksSpirale,
  keno,
  plus5,
  super6,
  spiel77,
  totoaw,
  totoew,
  toto,
  rubbellose,
];

export interface GameConfig {
  id: number;
  additionalGameId?: number;
  shortname: string;
  title: string;
  color?: string;
  logo: LogoVariant;
  logoBlack?: LogoVariant;
  logoColor?: LogoVariant;
  button: ButtonVariant;
  buttonSecondary: ButtonVariant;
  jackpotLabelPrefix?: string;
  jackpotLabelSuffix?: string;
  jackpotDesc: string;
  jackpotDescAlt?: string;
  jackpotDescGame?: string;
  jackpotOfflineDesc?: string;
  jackpotOfflineDescGame?: string;
  jackpotOfflineDescBadge?: string;
  jackpotSrc?: string;
  jackpotLast?: string;
  jackpotTimes: string;
  jackpotIsEurUnFormatted?: boolean;
  chanceDesc: string;
  imgPath?: string;
  basePrice?: number;
  couponGroup?: CouponGroupConfig;
  playNumber?: PlayNumberConfig;
  drawDays?: DrawDaysConfig;
  drawDeadlines?: readonly DrawDeadlineConfig[];
  weeksNumber?: WeeksNumberConfig[];
  weeklySubscription?: boolean;
  additionalGames?: GameConfig[];
  drawNumbers?: DrawNumberConfig[];
  drawRate?: DrawRateConfig[];
  drawOdds?: DrawRateOdds;
  drawTypes?: DrawTypeConfig[];
  label?: GameLabel;
  steps: GameStep[];
  variantSteps?: GameStep[];
  data?: {
    price: number;
    dependsOnDrawDays: boolean;
  };
  digitAmount?: number;
  apiUrls?: {
    shortinfo?: string;
    statistics?: string;
    winningNumbers?: string | string[];
    winningNumbersDate?: string;
  };
  urls: {
    overview?: string;
    stats: string;
    game: string;
    live?: string;
  };
  queryParamKey?: string;
  deadlineCols?: DeadlineCol[];
  info?: {
    [key: string]: {
      active?: boolean;
      title: string;
      desc: string;
    };
  };
  alternativeDesign?: {
    productLogoHeader?: LogoVariant;
    productLogoNavigation?: LogoVariant;
  };
}

export interface GameLabel {
  text: string;
  imgPath?: string;
}

export interface CouponNumberGroupConfig {
  amountNumbers: number;
  startValue: number;
  minCrossed: number;
  maxCrossed: number;
  title?: string;
  name?: string;
  hideCouponIndex?: boolean;
  columns: number;
  columnsVariant?: number;
}

export interface CouponGroupConfig {
  amountCoupons: number;
  couponPrice: number;
  width: number;
  columns: number;
  numberGroups: CouponNumberGroupConfig[];
  quickTipPerCoupon?: boolean;
  quickTips?: number[];
  columnsVariant?: number;
  layout?: 'side-by-side' | 'below-each-other';
}

export interface WeeksNumberConfig {
  value: number;
  label?: string;
}

export interface DrawNumberConfig {
  value: number;
}

export interface DrawRateConfig {
  value: number;
  label?: string;
  initial?: boolean;
}

export interface DrawDaysConfig {
  options: string[][];
  initial: string[];
  dynamic?: boolean;
}

export interface DrawDeadlineConfig {
  day: string;
  time: string;
}

export interface PlayNumberConfig {
  amountNumbers: number;
  superNumberIndex?: number;
  valueMin: number;
  valueMax: number;
  noHide?: boolean;
}

export interface DrawTypeConfig {
  label: string;
  value: number;
}

export interface DrawRateOdds {
  [key: number]: DrawRateOddItem[];
}

export interface DrawRateOddItem {
  winningClass: number;
  winningClassName: string;
  winningSum: number;
  odds: number;
}

export interface DeadlineCol {
  head: string;
  key: string;
}
