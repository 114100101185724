import { getNow } from '@/utilities/dateUtils';

export function isJackpotExpired(
  nextJackpot: { expire: number } | null
): boolean {
  const now = getNow();
  return (
    (nextJackpot && nextJackpot.expire !== 0
      ? nextJackpot.expire < now.unix()
      : false) || false
  );
}
