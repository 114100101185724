import type { CmsPage } from '@/components/CmsInterfaces';
import MarketingParams from '@/helpers/MarketingParams';
import React from 'react';

type PageProps<P> = P & CmsPage;
type PageState<S> = S;

export default class Page<P = {}, S = {}> extends React.Component<
  PageProps<P>,
  PageState<S>
> {
  constructor(props: PageProps<P>) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    MarketingParams.save();
  }
}
