import { Button } from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import { Icon, IconVariant } from '@/components/Icon';
import Modal, { ModalContent, ModalHeader } from '@/components/Modal';
import React from 'react';

export default class ModalNewUser extends React.Component {
  handleClose = () => {
    Modal.close('new-user');
  };

  render() {
    return (
      <Modal id="new-user">
        <ModalHeader quitAction={this.handleClose}>
          <Icon variant={IconVariant.Info} />
          <span>Information</span>
        </ModalHeader>
        <ModalContent>
          <h4>Serviceplattform LOTTO.de</h4>
          <p>
            Unter LOTTO.de haben die Landes&shy;lotterie&shy;gesell&shy;schaften
            des Deutschen Lotto- und Toto&shy;blocks als staatlich erlaubte
            Anbieter von Glücks&shy;spielen eine Service&shy;plattform rund um
            die Lotterien LOTTO 6aus49, Eurojackpot, Glücks&shy;Spirale und KENO
            eingerichtet. Auf LOTTO.de wird Ihnen ein Zugang zu den
            Spiel&shy;angeboten der Landes&shy;lotterie&shy;gesellschaften
            ermöglicht. Auf dieser Webseite können Sie für die genannten
            Lotterien Ihre Zahlen ankreuzen und die Live-Ziehung der
            Lotto&shy;zahlen verfolgen. Eine Registrierung oder Anmeldung zur
            Abgabe eines Spielscheins erfolgt auf der Webseite der
            Landes&shy;lotterie&shy;gesellschaft Ihres Bundes&shy;landes.
          </p>
          <Button variant={ButtonVariant.Primary} onClick={this.handleClose}>
            OK
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}
