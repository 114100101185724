import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_EUROJACKPOT } from '@/config/game/ids';
import {
  CouponGroupConfig,
  CouponNumberGroupConfig,
  DrawDaysConfig,
  DrawDeadlineConfig,
  GameConfig,
} from '@/config/game/index';
import { spiel77 } from '@/config/game/spiel77';
import { gameStepsDefault } from '@/config/game/steps';
import { super6 } from '@/config/game/super6';

const getDrawDays = (): DrawDaysConfig => {
  return {
    options: [['Dienstag'], ['Freitag'], ['Dienstag', 'Freitag']],
    initial: ['Freitag'],
    dynamic: true,
  };
};

const getDrawDeadlines = (): readonly DrawDeadlineConfig[] => {
  return [
    {
      day: 'Dienstag',
      time: '19:00',
    },
    {
      day: 'Freitag',
      time: '19:00',
    },
  ];
};

const getChance = () => {
  return 'Chance 1:140 Mio., Kl. 1. Ab 18 J.';
};

const getDeadlineCols = () => {
  return [
    {
      head: 'Bundesland',
      key: 'state',
    },
    {
      head: 'Dienstag',
      key: 'eurojackpotClosingDateTuesday',
    },
    {
      head: 'Freitag',
      key: 'eurojackpotClosingDate',
    },
  ];
};

const getCouponDesc = () => {
  return (
    'Auf einem Eurojackpot-Spielschein befinden sich 8 Tippfelder mit jeweils 50 Zahlen und 12 Eurozahlen. ' +
    'In einem Tippfeld müssen Sie jeweils 5 aus 50 Zahlen und 2 aus 12 Eurozahlen ankreuzen. Sie können ein Feld ' +
    'anklicken und die Zahlen selbst ankreuzen oder Sie nutzen bequem unsere Quick-Tipps: damit können Sie ' +
    'ein oder mehrere Tippfelder schnell und bequem per Zufall ausfüllen.'
  );
};

const getWeeksNumber = () => {
  return [
    { value: 1, label: '1 Woche' },
    { value: 2, label: '2 Wochen' },
    { value: 3, label: '3 Wochen' },
    { value: 4, label: '4 Wochen' },
    { value: 5, label: '5 Wochen' },
  ];
};

const getAdditionalGames = (): GameConfig['additionalGames'] => {
  return [spiel77, super6];
};

const getNumberGroups = (): CouponNumberGroupConfig[] => {
  return [
    {
      amountNumbers: 50,
      startValue: 1,
      minCrossed: 5,
      maxCrossed: 5,
      title: '5 aus 50',
      columns: 10,
      columnsVariant: 5,
    },
    {
      amountNumbers: 12,
      startValue: 1,
      minCrossed: 2,
      maxCrossed: 2,
      title: `2 aus 12`,
      name: 'Eurozahlen',
      columns: 10,
      columnsVariant: 5,
      hideCouponIndex: true,
    },
  ];
};

const getLayout = (): CouponGroupConfig['layout'] => {
  return 'below-each-other';
};

const changes2022_1_active = () => {
  return false;
};

const changes2022_2_active = () => {
  return false;
};

const getJackpotTimes = (): GameConfig['jackpotTimes'] => {
  return 'Jeden Dienstag und Freitag im Jackpot';
};

export const eurojackpot: GameConfig = {
  id: ID_EUROJACKPOT,
  shortname: 'eurojackpot',
  title: 'Eurojackpot',
  logo: LogoVariant.Eurojackpot,
  button: ButtonVariant.Eurojackpot,
  buttonSecondary: ButtonVariant.EurojackpotSecondary,
  jackpotSrc: API.stats.eurojackpotShortinfo,
  jackpotLast: API.stats.eurojackpotLast,
  jackpotDesc: 'Min. 10 Mio. €',
  jackpotOfflineDesc: 'Min. 10 Mio. €',
  jackpotOfflineDescGame: 'Jackpot Min. 10 Mio. €',
  jackpotTimes: getJackpotTimes(),
  chanceDesc: getChance(),
  couponGroup: {
    width: 200,
    columns: 2,
    columnsVariant: 3,
    couponPrice: 2,
    amountCoupons: 8,
    quickTips: [1, 2, 4, 6],
    layout: getLayout(),
    numberGroups: getNumberGroups(),
  },
  playNumber: {
    amountNumbers: 7,
    superNumberIndex: -1,
    valueMin: 0,
    valueMax: 9,
  },
  drawDays: getDrawDays(),
  drawDeadlines: getDrawDeadlines(),
  weeksNumber: getWeeksNumber(),
  additionalGames: getAdditionalGames(),
  steps: gameStepsDefault,
  apiUrls: {
    shortinfo: API.stats.eurojackpotLast,
    winningNumbers: API.stats.eurojackpotLast,
    winningNumbersDate: API.stats.eurojackpotDraw,
  },
  deadlineCols: getDeadlineCols(),
  urls: {
    overview: '/eurojackpot',
    stats: '/eurojackpot/zahlen',
    game: '/eurojackpot/spielen',
  },
  alternativeDesign: {
    productLogoNavigation: LogoVariant.EurojackpotProductRefresh
  },
  info: {
    coupons: {
      title: 'So tippen Sie Eurojackpot',
      desc: getCouponDesc(),
    },
    changes2022_1: {
      active: changes2022_1_active(),
      title: 'Eurojackpot-Produktänderung',
      desc: '',
    },
    changes2022_2: {
      active: changes2022_2_active(),
      title: 'Eurojackpot-Produktänderung',
      desc: '',
    },
  },
};

export const getOddsDatePickerFormat = () => {
  return undefined;
};
