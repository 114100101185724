import HeaderNavigation from '@/components/HeaderNavigation';
import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import { ListMenu } from '@/components/ListMenu';
import { LoadingInfo } from '@/components/LoadingInfo';
import {
  LoadingProgress,
  LoadingProgressSize,
} from '@/components/LoadingProgress';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import Modal from '@/components/Modal';
import ModalNewUser from '@/components/ModalNewUser';
import isStandaloneMode from '@/utilities/isStandaloneMode';
import classnames from 'classnames';
import React from 'react';

interface HeaderProps {
  backButton?: JSX.Element;
  parentPage?: string;
  hideNav?: boolean;
}

interface HeaderState {
  navigationExpanded: boolean;
  /**
   * same as navigation expanded
   * but needed because dom elements animate in
   * 1. Add Elements to DOM (but make them hidden) -> toggle navigationExpanded
   * 2. Animate elements to show -> toggle actuallyShowNavigation
   */
  actuallyShowNavigation: boolean;
  currentNavigationItem: string | null;
  isOpenAppLoader: boolean;
  fetchAppDone: boolean;
  isOpenOfflineInfo: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps, state: HeaderState) {
    super(props, state);
    this.state = {
      navigationExpanded: false,
      actuallyShowNavigation: false,
      currentNavigationItem: null,
      isOpenAppLoader: false,
      fetchAppDone: false,
      isOpenOfflineInfo: false,
    };
    this.handleTransitionEndClose = this.handleTransitionEndClose.bind(this);
  }

  private get hasNavigation(): boolean {
    return !this.props.hideNav;
  }

  private updateState = (
    navigationExpanded: boolean,
    currentNavigationItem: string | null
  ): void => {
    if (navigationExpanded) {
      this.clearTransitionEndHandler();
      // animate in
      this.setState(() => {
        return {
          navigationExpanded,
          currentNavigationItem,
        };
      });
      requestAnimationFrame(() => {
        this.setState(() => {
          return {
            actuallyShowNavigation: navigationExpanded,
          };
        });
      });
    } else {
      // animate out
      this.setState(() => {
        return {
          actuallyShowNavigation: navigationExpanded,
          currentNavigationItem,
        };
      });
      this.setupTransitionEndHandler();
    }
  };

  private toggleNavigation = (): void => {
    this.updateState(!this.state.navigationExpanded, null);
  };

  private openNavigation = (navigationItem: string): void => {
    if (navigationItem === this.state.currentNavigationItem) {
      this.closeNavigation();
    } else {
      this.updateState(true, navigationItem);
    }
  };

  private handleOnClickLoadingProgress = () =>
    this.setState(() => ({ isOpenOfflineInfo: true }));

  private handleOnClickOfflineInfo = () =>
    this.setState(() => ({ isOpenOfflineInfo: false }));

  private closeNavigation = (): void => {
    this.updateState(false, this.state.currentNavigationItem);
    setTimeout(() => {
      this.updateState(false, null);
    }, 300);
  };

  componentDidMount() {
    window.addEventListener(
      'changeFetchAppDataState',
      ($event: CustomEvent) => {
        const fetchAppDataState = $event.detail;
        const actions = {
          loading: { isOpenAppLoader: true },
          done: { fetchAppDone: true },
        };

        const action = actions[fetchAppDataState];

        if (action) {
          this.setState(
            () => action,
            () =>
              fetchAppDataState === 'done' &&
              setTimeout(() => {
                this.setState(
                  () => ({ isOpenAppLoader: false }),
                  () => ({ isOpenOfflineInfo: false })
                );
              }, 300)
          );
        }
      }
    );
  }

  componentWillUnmount() {
    this.clearTransitionEndHandler();
  }

  private clearTransitionEndHandler() {
    window.removeEventListener('transitionend', this.handleTransitionEndClose);
  }

  private setupTransitionEndHandler() {
    window.addEventListener('transitionend', this.handleTransitionEndClose, {
      once: true,
    });
  }

  /**
   * remove header from dom after animation is finished
   */
  private handleTransitionEndClose() {
    this.setState(() => {
      return {
        navigationExpanded: false,
      };
    });
  }

  render(): JSX.Element | string {
    return (
      <header
        className={classnames('Header', {
          'Header--no-navigation': !this.hasNavigation,
        })}
      >
        <ModalNewUser />
        <div className="Header__wrapper">
          {this.props.backButton && (
            <div className="Header__backButton">{this.props.backButton}</div>
          )}
          <section className="Header__section">
            {isStandaloneMode() && (
              <LoadingProgress
                visible={this.state.isOpenAppLoader}
                size={LoadingProgressSize.Small}
                finished={this.state.fetchAppDone}
                onClick={this.handleOnClickLoadingProgress}
              />
            )}

            {this.hasNavigation ? (
              this.props.parentPage ? (
                <LinkWrapper href="/" title="LOTTO.de" className="Header__logo">
                  <Logo variant={LogoVariant.Main} title="LOTTO.de" />
                </LinkWrapper>
              ) : (
                <h2>
                  <LinkWrapper
                    href="/"
                    title="LOTTO.de - Spielen beim Original!"
                    className="Header__logo"
                  >
                    <Logo variant={LogoVariant.Main} title="LOTTO.de" />
                  </LinkWrapper>
                </h2>
              )
            ) : (
              <span className="Header__logo Header__logo--no-link">
                <Logo variant={LogoVariant.Main} title="LOTTO.de" />
              </span>
            )}
            <span className="Header__slogan">
              <Logo
                variant={LogoVariant.Slogan}
                title="Spielen beim Original"
              />
            </span>
            <span
              className="Header__info"
              onClick={() => Modal.open('new-user')}
            >
              <Icon variant={IconVariant.Info} />
            </span>
          </section>
          {this.hasNavigation && !this.props.hideNav && (
            <>
              <section className="Header__section">
                <ListMenu
                  currentItem={this.state.currentNavigationItem}
                  onClick={this.openNavigation}
                  parentPage={this.props.parentPage || null}
                />
              </section>
              <button
                className="Header__trigger"
                title="Menü öffnen"
                onClick={this.toggleNavigation}
              >
                <i
                  className={classnames('BurgerMenuIcon', {
                    'BurgerMenuIcon--active': this.state.navigationExpanded,
                  })}
                >
                  <b className="BurgerMenuIcon__line" />
                  <b className="BurgerMenuIcon__line" />
                  <b className="BurgerMenuIcon__line" />
                </i>
              </button>
            </>
          )}
        </div>
        {this.hasNavigation &&
          !this.props.hideNav &&
          this.state.navigationExpanded && (
            <HeaderNavigation
              key={this.state.currentNavigationItem}
              currentNavigationItem={this.state.currentNavigationItem}
              expanded={this.state.actuallyShowNavigation}
              onClose={this.closeNavigation}
            />
          )}
        {isStandaloneMode() && (
          <LoadingInfo
            isOpen={this.state.isOpenOfflineInfo}
            isFinished={this.state.fetchAppDone}
            onClick={this.handleOnClickOfflineInfo}
          />
        )}
      </header>
    );
  }
}

export default Header;
