export enum LogoVariant {
  Main = 'logo-main',
  MainOnBackground = 'logo-main-on-background',
  Slogan = 'logo-slogan',
  SloganGray = 'logo-slogan-gray',
  Lotto6aus49 = 'logo-lotto6aus49',
  Lotto6aus49ProductRefresh = 'lotto-6aus49-campaign-march-24-logo',
  GluecksSpirale = 'logo-gluecksspirale',
  GluecksSpiraleBlack = 'logo-gluecksspirale-black',
  GluecksSpiraleColor = 'logo-gluecksspirale-color',
  Eurojackpot = 'logo-eurojackpot',
  EurojackpotProductRefresh = 'logo-eurojackpot-refresh',
  Keno = 'logo-keno',
  Spiel77 = 'logo-spiel77',
  Spiel77Black = 'logo-spiel77-black',
  Super6 = 'logo-super6',
  Super6Black = 'logo-super6-black',
  Plus5 = 'logo-plus5',
  Spiel77OnBackground = 'logo-spiel77-on-background',
  Super6OnBackground = 'logo-super6-on-background',
  Plus5OnBackground = 'logo-plus5-on-background',
  TotoAW = 'logo-totoaw',
  TotoEW = 'logo-totoew',
  Toto = 'logo-toto',
  Rubbellose = 'logo-rubbellose',
}
