import Button from '@/components/Button';
import { CmsNavigationAreaComponent } from '@/components/CmsInterfaces';
import { Icon, IconVariant } from '@/components/Icon';
import { NavigationLevel } from '@/components/NavigationLevel';
import navigation from '@/config/navigation';
import { getGameShortname } from '@/helpers/getGameShortname';
import createArrayFromNumber from '@/utilities/createArrayFromNumber';
import classnames from 'classnames';
import { useState } from 'react';

export default function Navigation({ initialItem, onClose }) {
  const [currentItems, setCurrentItems] = useState(
    initialItem !== null ? [initialItem] : []
  );

  const handleOnItemClick = (item: string, level: number): void => {
    const newItems = currentItems.slice(0, level);
    setCurrentItems(
      currentItems.indexOf(item) < 0 ? newItems.concat(item) : newItems
    );
  };

  const handleOnBackClick = (): void => {
    setCurrentItems(currentItems.slice(0, -1));
  };

  const isCurrentLevel = (id: string): boolean => {
    return (
      (!isRoot() && currentItems.indexOf(id) === currentItems.length - 1) ||
      (isRoot() && id === 'root')
    );
  };

  const isActiveLevel = (id: string): boolean => {
    return currentItems.indexOf(id) >= 0 || id === 'root';
  };

  const rootLevel = () => {
    return {
      root: {
        title: 'Navigation',
        overview: '',
        items: navigation,
      },
    };
  };

  const isRoot = () => {
    return currentItems.length === 0;
  };

  const currentRoot = () => {
    return rootLevel().root.items.find(
      (item) => item.id === (currentItems[0] || '')
    );
  };

  const currentTheme = () => {
    const game = +(currentRoot() ? currentRoot().theme : '0');
    return getGameShortname(game) || 'default';
  };

  const getInnerLevel = (
    level: number
  ): {
    [key: string]: {
      title: string;
      items: CmsNavigationAreaComponent['nodes'];
      themeId?: string;
      overviewLink?: string;
      gameLink?: string;
    };
  } => {
    const innerLevel = {};
    const outerLevel = getLevel(Math.max(level - 1, 0));
    Object.keys(outerLevel).forEach((id) => {
      outerLevel[id].items.forEach((outerLevelItem) => {
        if (outerLevelItem.navigation && outerLevelItem.navigation.nodes) {
          innerLevel[outerLevelItem.id] = {
            title: outerLevelItem.title,
            items: outerLevelItem.navigation.nodes,
            themeId: outerLevelItem.theme,
            gameLink: outerLevelItem.game && outerLevelItem.game.path,
            overviewLink:
              outerLevelItem.overview && outerLevelItem.overview.path,
          };
        }
      });
    });
    return innerLevel;
  };

  const getLevel = (level: number) => {
    switch (level) {
      case 0: {
        return rootLevel();
      }
      case 1:
      case 2: {
        return getInnerLevel(level);
      }
      default: {
        return {};
      }
    }
  };

  return (
    <div className={classnames('Navigation', `Navigation--${currentTheme()}`)}>
      <div className="Navigation__background">
        <div className="Navigation__wrapper">
          <button
            title="Zurück"
            onClick={handleOnBackClick}
            className={classnames('Navigation__back', {
              'Navigation__back--hidden': isRoot(),
            })}
          >
            <Icon variant={IconVariant.ChevronLeft} />
          </button>
          <div className="Navigation__panel">
            <Button
              onClick={onClose}
              variant={Button.Variant.Text}
              className="Navigation__close"
              iconPosition={Button.IconPosition.Right}
            >
              <span>Schließen</span>
              <Icon variant={IconVariant.Close} />
            </Button>
            {createArrayFromNumber(3, 0).map((index) => {
              const levels = getLevel(index);
              return Object.keys(levels).map((id) => {
                const level = levels[id];
                return (
                  <NavigationLevel
                    id={id}
                    key={id}
                    index={index}
                    items={level.items}
                    themeId={level.themeId}
                    title={level.title}
                    overviewLink={level.overviewLink}
                    gameLink={level.gameLink}
                    currentItems={currentItems}
                    isActive={isActiveLevel(id)}
                    isCurrent={isCurrentLevel(id)}
                    onClick={handleOnItemClick}
                    onClose={onClose}
                  />
                );
              });
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
