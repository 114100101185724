export type T_EUROJACKPOT = 1;
export type T_GLUECKSSPIRALE = 2;
export type T_KENO = 3;
export type T_LOTTO6AUS49 = 4;
export type T_PLUS5 = 5;
export type T_SUPER6 = 6;
export type T_SPIEL77 = 7;
export type T_TOTO_AW = 8;
export type T_TOTO_EW = 9;
export type T_TOTO = 10;
export type T_RUBBELLOSE = 11;

export const ID_EUROJACKPOT: T_EUROJACKPOT = 1;
export const ID_GLUECKSPIRALE: T_GLUECKSSPIRALE = 2;
export const ID_KENO: T_KENO = 3;
export const ID_LOTTO6AUS49: T_LOTTO6AUS49 = 4;
export const ID_PLUS5: T_PLUS5 = 5;
export const ID_SUPER6: T_SUPER6 = 6;
export const ID_SPIEL77: T_SPIEL77 = 7;
export const ID_TOTO_AW: T_TOTO_AW = 8;
export const ID_TOTO_EW: T_TOTO_EW = 9;
export const ID_TOTO: T_TOTO = 10;
export const ID_RUBBELLOSE: T_RUBBELLOSE = 11;
