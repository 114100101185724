import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_SPIEL77 } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';

export const spiel77: GameConfig = {
  id: ID_SPIEL77,
  additionalGameId: 1,
  shortname: 'spiel77',
  title: 'Spiel 77',
  color: '#0089c5',
  logo: LogoVariant.Spiel77OnBackground,
  logoBlack: LogoVariant.Spiel77,
  logoColor: LogoVariant.Spiel77,
  button: ButtonVariant.Primary,
  buttonSecondary: ButtonVariant.Secondary,
  jackpotSrc: API.stats.spiel77Shortinfo,
  jackpotLabelPrefix: 'Jackpot',
  jackpotDesc: 'Mind. 177.777 €',
  jackpotOfflineDesc: 'Mind. 177.777 €',
  jackpotOfflineDescGame: 'Mind. 177.777 €',
  jackpotOfflineDescBadge: 'n. v.',
  jackpotTimes: 'Jeden Mi. und Sa. im Jackpot',
  chanceDesc: 'Chance 1:10 Mio., Kl. 1. Ab 18 J.',
  drawDeadlines: [
    {
      day: 'Mittwoch',
      time: '18:00',
    },
    {
      day: 'Samstag',
      time: '19:00',
    },
  ],
  steps: [],
  urls: {
    stats: '/lotto-6aus49/lottozahlen',
    game: '/lotto-6aus49',
  },
  data: {
    price: 2.5,
    dependsOnDrawDays: true,
  },
  digitAmount: 7,
  label: {
    text: 'Spiel 77',
    imgPath: 'logo-spiel77.svg',
  },
  queryParamKey: 's77',
};
