import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_KENO } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';
import { plus5 } from '@/config/game/plus5';
import { gameStepsDefault } from '@/config/game/steps';
import { kenoData } from '@/config/odds/oddsConfigs';
import { kenoDrawOddsToDrawTypes } from '@/helpers/kenoDrawOddsToDrawType';

export const keno: GameConfig = {
  id: ID_KENO,
  shortname: 'keno',
  title: 'KENO',
  logo: LogoVariant.Keno,
  button: ButtonVariant.Keno,
  buttonSecondary: ButtonVariant.KenoSecondary,
  jackpotDesc: '1 Million €',
  jackpotTimes: 'Tag für Tag',
  chanceDesc: 'Chance 1:2,2 Mio., KENO-Typ 10. Ab 18 J.',
  couponGroup: {
    width: 280,
    columns: 2,
    columnsVariant: 3,
    amountCoupons: 5,
    couponPrice: 1,
    quickTips: [1, 2, 4],
    numberGroups: [
      {
        amountNumbers: 70,
        startValue: 1,
        minCrossed: 2,
        maxCrossed: 10,
        columns: 10,
        columnsVariant: 7,
      },
    ],
  },
  playNumber: {
    amountNumbers: 5,
    superNumberIndex: -1,
    valueMin: 0,
    valueMax: 9,
  },
  drawNumbers: [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 14 },
    { value: 28 },
  ],
  additionalGames: [plus5],
  drawRate: [
    { value: 1, label: '1 €', initial: true },
    { value: 2, label: '2 €' },
    { value: 5, label: '5 €' },
    { value: 10, label: '10 €' },
  ],
  drawTypes: kenoDrawOddsToDrawTypes(kenoData, `KENO-Typ`),
  drawOdds: kenoData,
  steps: gameStepsDefault,
  apiUrls: {
    shortinfo: `${API.stats.kenoLast}/1`,
    winningNumbers: `${API.stats.kenoLast}/1`,
    winningNumbersDate: API.stats.kenoDraw,
  },
  deadlineCols: [
    {
      head: 'Bundesland',
      key: 'state',
    },
    {
      head: 'Täglich',
      key: 'kenoClosingDate',
    },
  ],
  urls: {
    overview: '/keno',
    stats: '/keno/zahlen',
    game: '/keno/spielen',
  },
  info: {
    coupons: {
      title: 'So tippen Sie KENO',
      desc:
        'Auf einem KENO-Spielschein befinden sich 5 Tippfelder mit jeweils 70 Zahlen. ' +
        'In einem Tippfeld können Sie zwischen 2 und 10 Zahlen ankreuzen. ' +
        'Die Anzahl der angekreuzten Zahlen in einem Tippfeld definiert Ihren KENO-Typ: bei 3 getippten Zahlen ' +
        'spielen Sie z.B. KENO-Typ 3. Jeder KENO-Typ hat einen eigenen Gewinnplan mit festen Quoten. ' +
        'Sie können ein Tippfeld anklicken und die Zahlen selbst ankreuzen oder Sie nutzen bequem unsere ' +
        'Quick-Tipps: damit können Sie ein oder mehrere Tippfelder schnell und bequem per Zufall ' +
        'ausfüllen.',
    },
  },
};
