import { marketingConfig } from '@/config/misc/marketing';
import { areCookiesEnabled } from '@/helpers/areCookiesEnabled';
import Cookie from 'js-cookie';

export interface IMarketingParams {
  kampagne?: string;
  netzwerk?: string;
  partner?: string;
  werbemittel?: string;
  zusatzinfos?: string;
  laufzeit?: string;
}

export interface IMarketingConfig {
  cookieName: string;
  defaultCookieLifetime: number;
  params: IMarketingParams;
  paramAliases: {
    [key: string]: keyof IMarketingParams;
  };
}

interface URLSearchParamsE extends URLSearchParams {
  entries: () => IterableIterator<[string, string]>;
}
export default class MarketingParams {
  public static save(): void {
    if (URLSearchParams && window && areCookiesEnabled()) {
      const q = new URLSearchParams(window.location.search) as URLSearchParamsE;
      const newParams = Array.from(q.entries()).reduce((params, item) => {
        const val = item[1];
        const key = item[0].toLowerCase();
        const param = marketingConfig.paramAliases[key] || key;
        return val && key && marketingConfig.params[param]
          ? {
              ...params,
              ...{ [param]: val },
            }
          : params;
      }, {});

      if (Object.keys(newParams).length > 0) {
        Cookie.set(
          marketingConfig.cookieName,
          MarketingParams.encodeParams(newParams),
          {
            expires:
              parseInt((newParams as any).laufzeit) ||
              marketingConfig.defaultCookieLifetime,
            secure: true,
            sameSite: 'None',
          }
        );
      }
    }
  }

  public static reset(): void {
    if (areCookiesEnabled()) {
      Cookie.remove(marketingConfig.cookieName);
    }
  }

  public static get(): { [key: string]: string } {
    if (window && areCookiesEnabled()) {
      const params = MarketingParams.paramsFromCookie;
      if (params) {
        return Object.keys(params).reduce((acc, param) => {
          const val = params[param];
          const key = marketingConfig.params[param];
          return { ...acc, ...(key && val ? { [key]: val } : {}) };
        }, {});
      }
      return {};
    }
    return {};
  }
  public static getNetwork(): string | undefined {
    if (
      typeof window !== 'undefined' &&
      window &&
      areCookiesEnabled() &&
      MarketingParams.paramsFromCookie
    ) {
      const marketing = this.paramsFromCookie;
      if (marketing != null && marketing.netzwerk === 'payback') {
        return 'payback';
      } else if (
        marketing != null &&
        marketing.netzwerk === 'awin' &&
        marketing.partner !== undefined &&
        marketing.partner.startsWith('328571-')
      ) {
        return 'deutschlandcard';
      } else if (
        marketing != null &&
        marketing.netzwerk === 'awin' &&
        marketing.partner !== undefined &&
        marketing.partner.startsWith('320119-')
      ) {
        return 'shoop';
      }
    }
    return undefined;
  }
  public static getRewardCurrency(): string | undefined {
    if (this.getNetwork() === 'payback') {
      return 'PAYBACK-Punkte';
    } else if (this.getNetwork() === 'deutschlandcard') {
      return 'DeuschlandCard-Punkte';
    } else if (this.getNetwork() === 'shoop') {
      return 'Cashback';
    }
    return undefined;
  }
  public static getNetworkLogo(location: string): string | undefined {
    if (this.getNetwork() === 'payback') {
      return location === 'header'
        ? '/assets/images/paypack_weiss.webp'
        : '/assets/images/payback_blau.webp';
    } else if (this.getNetwork() === 'deutschlandcard') {
      return location === 'header'
        ? '/assets/images/deutschlandcard_weiss.webp'
        : '/assets/images/deutschlandcard_lila.webp';
    } else if (this.getNetwork() === 'shoop') {
      return location === 'header'
        ? '/assets/images/shoop-logo-weiss.webp'
        : '/assets/images/shoop-logo-blau.webp';
    }
    return undefined;
  }
  public static isCashback(): boolean {
    if (typeof window !== 'undefined' && window && areCookiesEnabled()) {
      if (!MarketingParams.paramsFromCookie) {
        MarketingParams.save();
      }

      if (MarketingParams.paramsFromCookie) {
        if (
          this.getNetwork() === 'payback' ||
          this.getNetwork() === 'deutschlandcard' ||
          this.getNetwork() === 'shoop'
        ) {
          return true;
        }
      }
    }
    return false;
  }

  private static get paramsFromCookie(): IMarketingParams | null {
    if (window && areCookiesEnabled()) {
      const params = Cookie.get(marketingConfig.cookieName);
      return params ? MarketingParams.decodeParams(params) : null;
    }
    return null;
  }

  private static decodeParams(cookie: string): IMarketingParams {
    try {
      return cookie
        .split('&')
        .reduce((params: IMarketingParams, item: string) => {
          const param = item.split('=');
          if (param.length === 2 && MarketingParams.isValidParam(param[0])) {
            return { ...params, ...{ [param[0]]: param[1] } };
          }
          return params;
        }, {});
    } catch (e) {
      return {};
    }
  }

  private static isValidParam(key: string): boolean {
    return Object.keys(marketingConfig.params).some((p) => key.startsWith(p));
  }

  private static encodeParams(params: IMarketingParams): string {
    return Object.keys(params)
      .filter((key) => key !== 'laufzeit')
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }
}
