export function areCookiesEnabled(): boolean {
  try {
    if (navigator) {
      return navigator.cookieEnabled;
    }
    return false;
  } catch (error) {
    return false;
  }
}
