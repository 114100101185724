import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_TOTO_AW } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';

export const totoaw: GameConfig = {
  id: ID_TOTO_AW,
  shortname: 'toto',
  title: 'TOTO 6aus45 Auswahlwette',
  logo: LogoVariant.TotoAW,
  button: ButtonVariant.Primary,
  buttonSecondary: ButtonVariant.Secondary,
  drawDeadlines: [
    {
      day: 'Samstag',
      time: '13:00',
    },
  ],
  jackpotDesc: 'wird ermittelt',
  jackpotOfflineDesc: 'wird ermittelt',
  jackpotSrc: API.stats.totoawShortinfo,
  jackpotTimes: 'Diesen Samstag im Jackpot',
  jackpotIsEurUnFormatted: true,
  chanceDesc: 'Chance 1:8,2 Mio., Kl. 1. Ab 18 J.',
  steps: [],
  urls: {
    stats: '/ueber/toto-fussballwette/toto-gewinnzahlen-und-quoten',
    game: '/ueber/toto-fussballwette',
  },
  apiUrls: {
    shortinfo: API.stats.totoawShortinfo,
    winningNumbers: API.stats.totoawLast,
    winningNumbersDate: API.stats.totoawDraw,
  },
};
