import { IMarketingConfig } from '@/helpers/MarketingParams';

export const marketingConfig: IMarketingConfig = {
  cookieName: 'marketing',
  defaultCookieLifetime: 30,
  params: {
    kampagne: 'campaign',
    netzwerk: 'network',
    partner: 'partner',
    werbemittel: 'advertisingMaterial',
    zusatzinfos: 'additionalInfo',
    laufzeit: 'lifetime',
  },
  paramAliases: {
    k: 'kampagne',
    n: 'netzwerk',
    p: 'partner',
    w: 'werbemittel',
    z: 'zusatzinfos',
    zusatzinfo: 'zusatzinfos',
    l: 'laufzeit',
  },
};
