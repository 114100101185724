import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import API from '@/config/api';
import { ID_PLUS5 } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';

export const plus5: GameConfig = {
  id: ID_PLUS5,
  additionalGameId: 3,
  shortname: 'plus5',
  title: 'plus 5',
  logo: LogoVariant.Plus5OnBackground,
  logoBlack: LogoVariant.Plus5,
  logoColor: LogoVariant.Plus5,
  color: '#000000',
  button: ButtonVariant.Primary,
  buttonSecondary: ButtonVariant.Secondary,
  jackpotDesc: '5.000 €',
  jackpotTimes: 'Zusätzlich zum Gewinn',
  chanceDesc: 'Chance 1:100.000., Kl. 1. Ab 18 J.',
  steps: [],
  apiUrls: {
    shortinfo: `${API.stats.plus5Draw}/1`,
    winningNumbers: `${API.stats.plus5Last}/1`,
    winningNumbersDate: API.stats.plus5History,
  },
  urls: {
    stats: '/keno/zahlen',
    game: '/keno',
  },
  jackpotLabelSuffix: 'Höchstgewinn',
  label: {
    text: 'Plus 5',
    imgPath: 'logo-plus5.svg',
  },
  data: {
    price: 0.75,
    dependsOnDrawDays: false,
  },
  digitAmount: 5,
  queryParamKey: 'p5',
};
