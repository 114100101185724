import { CmsNavigationRootComponent } from '@/components/CmsInterfaces';

const navigation: CmsNavigationRootComponent[] = [
  {
    id: '0',
    theme: '4',
    game: {
      id: '1',
      path: '/lotto-6aus49/spielen',
    },
    title: 'LOTTO 6aus49',
    overview: {
      id: '2',
      path: '/lotto-6aus49',
    },
    navigation: {
      id: '3',
      nodes: [
        {
          id: '4',
          link: {
            path: '/lotto-6aus49/spielen',
          },
          icon: 'icon-cross',
          title: 'Spielen',
        },
        {
          id: '5',
          link: {
            path: '/lotto-6aus49/lottozahlen',
          },
          icon: 'icon-goblet',
          title: 'Zahlen & Quoten',
        },
        {
          id: '6',
          link: {
            path: '/lotto-6aus49/lotto-ziehung',
          },
          icon: 'icon-tv',
          title: 'LIVE-Ziehung',
        },
        {
          id: '7',
          link: {
            path: '/lotto-6aus49/spielregeln',
          },
          icon: 'icon-whistle',
          title: 'Spielregeln',
        },
        {
          id: '8',
          icon: 'icon-barchart',
          title: 'Statistik',
          navigation: {
            nodes: [
              {
                id: '9',
                link: {
                  path: '/lotto-6aus49/statistik/ziehungshaeufigkeit',
                },
                title: 'Ziehungshäufigkeit',
              },
              {
                id: '10',
                link: {
                  path: '/lotto-6aus49/statistik/barometer',
                },
                title: 'Barometer',
              },
              {
                id: '11',
                link: {
                  path: '/lotto-6aus49/statistik/histogramm',
                },
                title: 'Histogramm',
              },
            ],
          },
        },
        {
          id: '12',
          icon: 'icon-info',
          title: 'Informationen',
          navigation: {
            id: '13',
            nodes: [
              {
                id: '14',
                link: {
                  path: '/lotto-6aus49/info/annahmeschluss',
                },
                title: 'Annahmeschluss',
              },
              {
                id: '15',
                link: {
                  path: '/lotto-6aus49/info/gewinnwahrscheinlichkeit',
                },
                title: 'Gewinnwahrscheinlichkeit',
              },
              {
                id: '16',
                link: {
                  path: '/lotto-6aus49/info/maximal-jackpot',
                },
                title: 'Maximal-Jackpot',
              },
              {
                id: '17',
                link: {
                  path: '/lotto-6aus49/info/lotto-mit-system-vollsysteme-teilsysteme',
                },
                title: 'LOTTO mit System',
              },
              {
                id: '18',
                link: {
                  path: '/lotto-6aus49/info/lotto-spielgemeinschaften-tippgemeinschaften',
                },
                title: 'LOTTO-Spielgemeinschaften',
              },
              {
                id: '19',
                link: {
                  path: '/lotto-6aus49/info/sonderauslosungen',
                },
                title: 'Sonderauslosungen',
              },
            ],
          },
        },
        {
          id: '20',
          icon: 'icon-lightbulb',
          title: 'Alles zu LOTTO 6aus49',
          navigation: {
            id: '21',
            nodes: [
              {
                id: '22',
                link: {
                  path: '/lotto-6aus49/ueber/lotto-studio',
                },
                title: 'LOTTO-Studio',
              },
              {
                id: '23',
                link: {
                  path: '/lotto-6aus49/ueber/ziehungsablaeufe',
                },
                title: 'Ziehungsabläufe',
              },
              {
                id: '24',
                link: {
                  path: '/lotto-6aus49/ueber/lotto-kugeln',
                },
                title: 'LOTTO-Kugeln',
              },
              {
                id: '25',
                link: {
                  path: '/lotto-6aus49/ueber/ausstattung',
                },
                title: 'Ausstattung',
              },
              {
                id: '26',
                link: {
                  path: '/lotto-6aus49/ueber/moderation',
                },
                title: 'Moderation',
              },
              {
                id: '27',
                link: {
                  path: '/lotto-6aus49/ueber/historie',
                },
                title: 'Historie',
              },
            ],
          },
        },
        {
          id: '28',
          icon: 'icon-lotto',
          title: 'Spiel 77 & SUPER 6',
          navigation: {
            id: '29',
            nodes: [
              {
                id: '30',
                link: {
                  path: '/lotto-6aus49/spiel77-super6/spielregeln',
                },
                title: 'Spiel 77 & SUPER 6: Spielregeln',
              },
              {
                id: '31',
                link: {
                  path: '/lotto-6aus49/spiel77-super6/zahlen',
                },
                title: 'Spiel 77 & SUPER 6: Zahlen & Quoten',
              },
              {
                id: '32',
                link: {
                  path: '/lotto-6aus49/spiel77-super6/gewinnwahrscheinlichkeit',
                },
                title: 'Spiel 77 & SUPER 6: Gewinnwahrscheinlichkeit',
              },
              {
                id: '33',
                link: {
                  path: '/lotto-6aus49/spiel77-super6/maximal-jackpot',
                },
                title: 'Spiel 77: Maximal-Jackpot',
              },
              {
                id: '34',
                link: {
                  path: '/lotto-6aus49/spiel77-super6/sonderauslosungen',
                },
                title: 'Spiel 77: Sonderauslosungen',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: '35',
    theme: '1',
    game: {
      id: '36',
      path: '/eurojackpot/spielen',
    },
    title: 'Eurojackpot',
    overview: {
      id: '37',
      path: '/eurojackpot',
    },
    navigation: {
      id: '38',
      nodes: [
        {
          id: '39',
          link: {
            path: '/eurojackpot/spielen',
          },
          icon: 'icon-cross',
          title: 'Spielen',
        },
        {
          id: '40',
          link: {
            path: '/eurojackpot/zahlen',
          },
          icon: 'icon-goblet',
          title: 'Zahlen & Quoten',
        },
        {
          id: '41',
          link: {
            path: '/eurojackpot/ziehung',
          },
          shortTitle: 'Ziehung',
          icon: 'icon-tv',
          title: 'Ziehung',
        },
        {
          id: '42',
          link: {
            path: '/eurojackpot/spielregeln',
          },
          icon: 'icon-whistle',
          title: 'Spielregeln',
        },
        {
          id: '43',
          icon: 'icon-info',
          title: 'Informationen',
          navigation: {
            id: '44',
            nodes: [
              {
                id: '45',
                link: {
                  path: '/eurojackpot/produktaenderung-2022',
                },
                title: 'Produktänderung 2022',
              },
              {
                id: '46',
                link: {
                  path: '/eurojackpot/annahmeschluss',
                },
                title: 'Annahmeschluss',
              },
              {
                id: '47',
                link: {
                  path: '/eurojackpot/gewinnwahrscheinlichkeit',
                },
                title: 'Gewinnwahrscheinlichkeit',
              },
              {
                id: '48',
                link: {
                  path: '/eurojackpot/eurojackpot-system',
                },
                title: 'Eurojackpot-System',
              },
              {
                id: '49',
                link: {
                  path: '/eurojackpot/hoechstgewinne',
                },
                title: 'Höchstgewinne',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: '50',
    theme: '2',
    game: {
      id: '51',
      path: '/gluecksspirale/spielen',
    },
    title: 'GlücksSpirale',
    overview: {
      id: '52',
      path: '/gluecksspirale',
    },
    navigation: {
      id: '53',
      nodes: [
        {
          id: '54',
          link: {
            path: '/gluecksspirale/spielen',
          },
          icon: 'icon-cross',
          title: 'Spielen',
        },
        {
          id: '55',
          link: {
            path: '/gluecksspirale/gewinnzahlen',
          },
          icon: 'icon-goblet',
          title: 'Zahlen & Quoten',
        },
        {
          id: '56',
          link: {
            path: '/gluecksspirale/spielregeln',
          },
          icon: 'icon-whistle',
          title: 'Spielregeln',
        },
        {
          id: '57',
          icon: 'icon-info',
          title: 'Informationen',
          navigation: {
            id: '58',
            nodes: [
              {
                id: '59',
                link: {
                  path: '/gluecksspirale/info/annahmeschluss',
                },
                title: 'Annahmeschluss',
              },
              {
                id: '60',
                link: {
                  path: '/gluecksspirale/info/gewinnwahrscheinlichkeit',
                },
                title: 'Gewinnwahrscheinlichkeit',
              },
              {
                id: '61',
                link: {
                  path: '/gluecksspirale/info/sonderauslosungen',
                },
                title: 'Sonderauslosungen',
              },
              {
                id: '62',
                link: {
                  path: '/gluecksspirale/info/sieger-chance',
                },
                title: 'Die Sieger-Chance',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: '63',
    theme: '3',
    game: {
      id: '64',
      path: '/keno/spielen',
    },
    title: 'KENO',
    overview: {
      id: '65',
      path: '/keno',
    },
    navigation: {
      id: '66',
      nodes: [
        {
          id: '67',
          link: {
            path: '/keno/spielen',
          },
          icon: 'icon-cross',
          title: 'Spielen',
        },
        {
          id: '68',
          link: {
            path: '/keno/zahlen',
          },
          icon: 'icon-goblet',
          title: 'Zahlen & Quoten',
        },
        {
          id: '69',
          link: {
            path: '/keno/ziehung',
          },
          shortTitle: 'Ziehung',
          icon: 'icon-tv',
          title: 'Ziehung',
        },
        {
          id: '70',
          link: {
            path: '/keno/spielregeln',
          },
          icon: 'icon-whistle',
          title: 'Spielregeln',
        },
        {
          id: '71',
          icon: 'icon-info',
          title: 'Informationen',
          navigation: {
            id: '72',
            nodes: [
              {
                id: '73',
                link: {
                  path: '/keno/info/annahmeschluss',
                },
                title: 'Annahmeschluss',
              },
              {
                id: '74',
                link: {
                  path: '/keno/info/gewinnwahrscheinlichkeit',
                },
                title: 'Gewinnwahrscheinlichkeit',
              },
              {
                id: '75',
                link: {
                  path: '/keno/info/zusatzauslosungen',
                },
                title: 'Zusatzauslosungen',
              },
            ],
          },
        },
      ],
    },
  },
  {
    id: '1100',
    theme: '11',
    title: 'Rubbellose',
    navigation: {
      id: '1110',
      nodes: [
        {
          id: '1111',
          link: {
            path: '/rubbellose/informationen',
          },
          icon: 'icon-info',
          title: 'Informationen',
        },
        {
          id: '1113',
          link: {
            path: '/rubbellose/rubbellos-goldene-7',
          },
          icon: 'icon-moneybag',
          title: 'Rubbellos Goldene 7',
        },
        {
          id: '1112',
          link: {
            path: '/rubbellose/rubbellos-platin-7',
          },
          icon: 'icon-moneybag',
          title: 'Rubbellos Platin 7',
        },
        {
          id: '1114',
          link: {
            path: '/rubbellose/rubbellos-diamant-7',
          },
          icon: 'icon-moneybag',
          title: 'Rubbellos Diamant 7',
        },
      ],
    },
  },
  {
    id: '81',
    theme: '0',
    title: 'Über...',
    navigation: {
      id: '82',
      nodes: [
        {
          id: '83',
          link: {
            path: '/ueber/mein-lotto',
          },
          icon: 'icon-login',
          title: 'Mein LOTTO | Registrierung',
        },
        {
          id: '84',
          link: {
            path: '/ueber/neuigkeiten',
          },
          icon: 'icon-megaphone',
          title: 'Neuigkeiten',
        },
        {
          id: '85',
          link: {
            path: '/ueber/faq',
          },
          icon: 'icon-questions',
          title: 'Häufige Fragen',
        },
        {
          id: '86',
          link: {
            path: '/ueber/kundenservice',
          },
          icon: 'icon-phone',
          title: 'Kundenservice',
        },
        {
          id: '87',
          link: {
            path: '/ueber/gluecksspielstaatsvertrag',
          },
          icon: 'icon-contract',
          title: 'Glücksspielstaatsvertrag',
        },
        {
          id: '88',
          link: {
            path: '/ueber/toto-fussballwette',
          },
          icon: 'icon-football',
          title: 'TOTO – Die Fußballwette',
        },
        {
          id: '89',
          link: {
            path: '/ueber/deutscher-lotto-und-totoblock',
          },
          icon: 'icon-lotto',
          title: 'Deutscher Lotto- und Totoblock',
        },
        {
          id: '90',
          link: {
            path: '/ueber/tests-und-auszeichnungen',
          },
          icon: 'icon-badge',
          title: 'Tests & Auszeichnungen',
        },
      ],
    },
  },
];

export default navigation;
