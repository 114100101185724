export interface ICancelablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function <T>(promise: Promise<T>): ICancelablePromise<T> {
  let hasCanceled_ = false;

  const wrappedPromise: Promise<T> = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}
