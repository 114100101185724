import API from '@/config/api';

export const oddsConfigs = {
  lotto: {
    title: 'LOTTO 6aus49 Gewinnquoten',
    romanClasses: false,
    gameType: 'lotto',
    rootKey: null,
    headColumns: ['Gewinnklasse', 'Anzahl Richtige', 'Gewinne', 'Quoten'],
    apiUrl: API.stats.lottoDraws,
    datesApiUrl: API.stats.lottoHistory,
  },
  spiel77: {
    title: 'Spiel 77 Gewinnquoten',
    romanClasses: true,
    gameType: 'spiel77',
    rootKey: 'game77',
    headColumns: ['Gewinnklasse', 'Anzahl Richtige', 'Gewinne', 'Gewinnbetrag'],
    apiUrl: API.stats.lottoDraws,
    datesApiUrl: API.stats.lottoHistory,
  },
  super6: {
    title: 'SUPER 6 Gewinnquoten',
    romanClasses: true,
    gameType: 'super6',
    rootKey: 'super6',
    headColumns: ['Gewinnklasse', 'Anzahl Richtige', 'Gewinne', 'Gewinnbetrag'],
    apiUrl: API.stats.lottoDraws,
    datesApiUrl: API.stats.lottoHistory,
  },
  gluecksspirale: {
    title: 'Glücksspirale Gewinnquoten',
    romanClasses: true,
    gameType: 'gluecksspirale',
    rootKey: null,
    headColumns: ['Gewinnklasse', 'Gewinnzahlen', 'Gewinne*', 'Gewinnbetrag'],
    apiUrl: API.stats.gluecksspiraleDraw,
    datesApiUrl: API.stats.gluecksspiraleHistory,
  },
  eurojackpot: {
    title: 'Eurojackpot Gewinnquoten',
    romanClasses: false,
    gameType: 'eurojackpot',
    rootKey: null,
    headColumns: ['Gewinnklasse', 'Anzahl Richtige', 'Gewinne', 'Gewinnbetrag'],
    apiUrl: API.stats.eurojackpotDraw,
    datesApiUrl: API.stats.eurojackpotHistory,
  },
  keno: {
    title: 'KENO Gewinnquoten',
    romanClasses: false,
    gameType: 'keno',
    rootKey: null,
    headColumns: [
      'Gewinnklasse',
      'Anzahl Richtige',
      'Gewinne*',
      'Chance',
      'Gewinnbetrag',
    ],
    apiUrl: API.stats.kenoDraw,
    datesApiUrl: API.stats.kenoHistory,
  },
  plus5: {
    title: 'plus5 Gewinnquoten',
    romanClasses: false,
    gameType: 'plus5',
    rootKey: null,
    headColumns: [
      'Gewinnklasse',
      'Anzahl Richtige',
      'Gewinne',
      'Chance',
      'Gewinnbetrag',
    ],
    apiUrl: API.stats.plus5Draw,
    datesApiUrl: API.stats.kenoHistory,
  },
  totoaw: {
    title: 'totoaw Gewinnquoten',
    romanClasses: false,
    gameType: null,
    rootKey: null,
    headColumns: ['Gewinnklasse', 'Anzahl Richtige', 'Gewinne', 'Quoten'],
    apiUrl: API.stats.totoawDraw,
    datesApiUrl: API.stats.totoawHistory,
    shortInfo: API.stats.totoawShortinfo,
  },
  totoew: {
    title: 'totoew Gewinnquoten',
    romanClasses: false,
    gameType: null,
    rootKey: null,
    headColumns: ['Gewinnklasse', 'Anzahl Richtige', 'Gewinne', 'Quoten'],
    apiUrl: API.stats.totoewDraw,
    datesApiUrl: API.stats.totoewHistory,
    shortInfo: API.stats.totoewShortinfo,
  },
};

export interface KenoDataInterface {
  [id: number]: Array<{
    winningClass: number;
    winningClassName: string;
    winningSum: number;
    odds: number;
  }>;
}

export const kenoData: KenoDataInterface = {
  2: [
    {
      winningClass: 2,
      winningClassName: '2 Richtige',
      winningSum: 6,
      odds: 13,
    },
  ],
  3: [
    {
      winningClass: 3,
      winningClassName: '3 Richtige',
      winningSum: 16,
      odds: 48,
    },
    {
      winningClass: 2,
      winningClassName: '2 Richtige',
      winningSum: 1,
      odds: 6,
    },
  ],
  4: [
    {
      winningClass: 4,
      winningClassName: '4 Richtige',
      winningSum: 22,
      odds: 189,
    },
    {
      winningClass: 3,
      winningClassName: '3 Richtige',
      winningSum: 2,
      odds: 16,
    },
    {
      winningClass: 2,
      winningClassName: '2 Richtige',
      winningSum: 1,
      odds: 4,
    },
  ],
  5: [
    {
      winningClass: 5,
      winningClassName: '5 Richtige',
      winningSum: 100,
      odds: 781,
    },
    {
      winningClass: 4,
      winningClassName: '4 Richtige',
      winningSum: 7,
      odds: 50,
    },
    {
      winningClass: 3,
      winningClassName: '3 Richtige',
      winningSum: 2,
      odds: 9,
    },
  ],
  6: [
    {
      winningClass: 6,
      winningClassName: '6 Richtige',
      winningSum: 500,
      odds: 3383,
    },
    {
      winningClass: 5,
      winningClassName: '5 Richtige',
      winningSum: 15,
      odds: 169,
    },
    {
      winningClass: 4,
      winningClassName: '4 Richtige',
      winningSum: 2,
      odds: 22,
    },
    {
      winningClass: 3,
      winningClassName: '3 Richtige',
      winningSum: 1,
      odds: 6,
    },
  ],
  7: [
    {
      winningClass: 7,
      winningClassName: '7 Richtige',
      winningSum: 1000,
      odds: 15464,
    },
    {
      winningClass: 6,
      winningClassName: '6 Richtige',
      winningSum: 100,
      odds: 619,
    },
    {
      winningClass: 5,
      winningClassName: '5 Richtige',
      winningSum: 12,
      odds: 63,
    },
    {
      winningClass: 4,
      winningClassName: '4 Richtige',
      winningSum: 1,
      odds: 13,
    },
  ],
  8: [
    {
      winningClass: 8,
      winningClassName: '8 Richtige',
      winningSum: 10000,
      odds: 74941,
    },
    {
      winningClass: 7,
      winningClassName: '7 Richtige',
      winningSum: 100,
      odds: 2436,
    },
    {
      winningClass: 6,
      winningClassName: '6 Richtige',
      winningSum: 15,
      odds: 199,
    },
    {
      winningClass: 5,
      winningClassName: '5 Richtige',
      winningSum: 2,
      odds: 31,
    },
    {
      winningClass: 4,
      winningClassName: '4 Richtige',
      winningSum: 1,
      odds: 8,
    },
    {
      winningClass: 0,
      winningClassName: '0 Richtige',
      winningSum: 1,
      odds: 18,
    },
  ],
  9: [
    {
      winningClass: 9,
      winningClassName: '9 Richtige',
      winningSum: 50000,
      odds: 387197,
    },
    {
      winningClass: 8,
      winningClassName: '8 Richtige',
      winningSum: 1000,
      odds: 10325,
    },
    {
      winningClass: 7,
      winningClassName: '7 Richtige',
      winningSum: 20,
      odds: 685,
    },
    {
      winningClass: 6,
      winningClassName: '6 Richtige',
      winningSum: 5,
      odds: 86,
    },
    {
      winningClass: 5,
      winningClassName: '5 Richtige',
      winningSum: 2,
      odds: 18,
    },
    {
      winningClass: 0,
      winningClassName: '0 Richtige',
      winningSum: 2,
      odds: 26,
    },
  ],
  10: [
    {
      winningClass: 10,
      winningClassName: '10 Richtige',
      winningSum: 100000,
      odds: 2147181,
    },
    {
      winningClass: 9,
      winningClassName: '9 Richtige',
      winningSum: 1000,
      odds: 47238,
    },
    {
      winningClass: 8,
      winningClassName: '8 Richtige',
      winningSum: 100,
      odds: 2571,
    },
    {
      winningClass: 7,
      winningClassName: '7 Richtige',
      winningSum: 15,
      odds: 261,
    },
    {
      winningClass: 6,
      winningClassName: '6 Richtige',
      winningSum: 5,
      odds: 44,
    },
    {
      winningClass: 5,
      winningClassName: '5 Richtige',
      winningSum: 2,
      odds: 12,
    },
    {
      winningClass: 0,
      winningClassName: '0 Richtige',
      winningSum: 2,
      odds: 39,
    },
  ],
};

export const plus5Data = [
  {
    winningClass: 1,
    winningClassName: '5 richtige Endziffern',
    winningSum: 5000,
    odds: 100000,
  },
  {
    winningClass: 2,
    winningClassName: '4 richtige Endziffern',
    winningSum: 500,
    odds: 11111,
  },
  {
    winningClass: 3,
    winningClassName: '3 richtige Endziffern',
    winningSum: 50,
    odds: 1111,
  },
  {
    winningClass: 4,
    winningClassName: '2 richtige Endziffern',
    winningSum: 5,
    odds: 111,
  },
  {
    winningClass: 5,
    winningClassName: '1 richtige Endziffer',
    winningSum: 2,
    odds: 11,
  },
];
