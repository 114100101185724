import classnames from 'classnames';

import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';

export function NavigationTitle({
  title,
  index,
  gameId,
  logo,
  overview,
  onClose,
}) {
  const renderText = () => {
    return <b className="NavigationTitle__text">{title}</b>;
  };

  const renderLogo = () => {
    const variant = logo || LogoVariant.Slogan;

    return (
      <Logo
        className={classnames(
          'NavigationTitle__logo',
          'NavigationTitle__logo--' + gameId,
          {
            'NavigationTitle__logo--center': variant === LogoVariant.Slogan,
          }
        )}
        title={logo ? title : 'Spielen beim Original'}
        variant={variant}
      />
    );
  };

  const renderOverview = () => {
    return overview ? (
      <LinkWrapper
        className="NavigationTitle__overview"
        href={overview}
        title={title}
        onClick={onClose}
      >
        Alles im Überblick
        <Icon variant={IconVariant.ArrowRight} />
      </LinkWrapper>
    ) : null;
  };

  return (
    <header className="NavigationTitle">
      {index === 0 && logo === null && renderLogo()}
      {index === 1 && logo && renderLogo()}
      {index === 1 && overview && renderOverview()}
      {index === 1 && !logo && !overview && renderText()}
      {index === 2 && renderText()}
    </header>
  );
}
