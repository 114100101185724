import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import { ID_TOTO } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';

export const toto: GameConfig = {
  id: ID_TOTO,
  shortname: 'toto',
  title: 'toto',
  logo: LogoVariant.Toto,
  button: ButtonVariant.Primary,
  buttonSecondary: ButtonVariant.Secondary,
  jackpotDesc: '',
  jackpotTimes: '',
  chanceDesc: '',
  steps: [],
  urls: { stats: '', game: '/toto' },
  apiUrls: {},
};
