export enum GameStep {
  Coupons = 'Coupons',
  CouponsB = 'CouponsB',
  Details = 'Details',
  Summary = 'Summary',
  Submit = 'Submit',
}

export const gameStepsDefault: GameStep[] = [
  GameStep.Coupons,
  GameStep.Details,
  GameStep.Summary,
  GameStep.Submit,
];

export const gameStepsGluecksspirale: GameStep[] = [
  GameStep.Details,
  GameStep.Summary,
  GameStep.Submit,
];

export const gameStepsLottoB: GameStep[] = [
  GameStep.CouponsB,
  GameStep.Summary,
  GameStep.Submit,
];
