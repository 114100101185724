import React from 'react';
import ReactDOM from 'react-dom';

interface IPortalProps {
  selector: string;
}

export const Portal: React.FC<React.PropsWithChildren<IPortalProps>> = (
  props
) => {
  const element = React.useRef<Element | null>();
  React.useEffect(() => {
    element.current =
      (document && document.querySelector(props.selector)) || null;
  }, [props.selector]);
  return !!element.current && !!props.children
    ? ReactDOM.createPortal(props.children, element.current)
    : null;
};
