import { IconVariant } from '@/components/Icon';
import { NavigationItem } from '@/components/NavigationItem';

export function NavigationList({
  items,
  level,
  currentItems,
  onClick,
  onClose,
}) {
  const isCurrentItem = (id: string) => {
    return currentItems.indexOf(id) >= 0;
  };

  return (
    <ul className="NavigationList">
      {level === 0 && (
        <NavigationItem
          key={'home'}
          item={{
            title: 'Startseite',
            link: { path: '/' },
            icon: IconVariant.Home,
            name: '',
            id: '',
            path: '',
            template: '',
            nodes: [],
          }}
          isCurrent={false}
          onClick={() => void 0}
          onClose={onClose}
        />
      )}
      {items.map((item) => (
        <NavigationItem
          key={item.id}
          item={item}
          isCurrent={isCurrentItem(item.id)}
          onClick={(id) => onClick(id, level)}
          onClose={onClose}
        />
      ))}
    </ul>
  );
}
