import { DrawTypeConfig } from '@/config/game';
import { KenoDataInterface } from '@/config/odds/oddsConfigs';

export function kenoDrawOddsToDrawTypes(
  drawOdds: KenoDataInterface,
  labelPrefix: string
): DrawTypeConfig[] {
  return Object.keys(drawOdds).map((value) => ({
    label: `${labelPrefix} ${value}`,
    value: +value,
  }));
}
