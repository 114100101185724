import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import { ID_SUPER6 } from '@/config/game/ids';
import { GameConfig } from '@/config/game/index';

export const super6: GameConfig = {
  id: ID_SUPER6,
  additionalGameId: 2,
  shortname: 'super6',
  title: 'Super 6',
  color: '#c30061',
  logo: LogoVariant.Super6OnBackground,
  logoBlack: LogoVariant.Super6,
  logoColor: LogoVariant.Super6,
  button: ButtonVariant.Primary,
  jackpotLabelSuffix: 'Höchstgewinn',
  jackpotDesc: '100.000 €',
  buttonSecondary: ButtonVariant.Secondary,
  jackpotTimes: 'Jeden Mittwoch und Samstag',
  chanceDesc: 'Chance 1:1 Mio., Kl. 1. Ab 18 J.',
  steps: [],
  urls: {
    stats: '/lotto-6aus49/lottozahlen',
    game: '/lotto-6aus49',
  },
  label: {
    text: 'Super 6',
    imgPath: 'logo-super6.svg',
  },
  data: {
    price: 1.25,
    dependsOnDrawDays: true,
  },
  drawDeadlines: [
    {
      day: 'Mittwoch',
      time: '18:00',
    },
    {
      day: 'Samstag',
      time: '19:00',
    },
  ],
  digitAmount: 6,
  queryParamKey: 's6',
};
