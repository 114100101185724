import Button from '@/components/Button';
import { Icon, IconVariant } from '@/components/Icon';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface NotificationBannerProps {
  title: string;
  text: string;
  isOpen: boolean;
  icon: JSX.Element;
  onClose: () => void;
}

export const NotificationBanner: React.FC<
  React.PropsWithChildren<NotificationBannerProps>
> = ({ title, text, isOpen, icon, onClose }) => (
  <TransitionGroup>
    {isOpen && (
      <CSSTransition classNames="NotificationBanner" timeout={300}>
        <div className="NotificationBanner" onClick={() => onClose()}>
          <div className="NotificationBanner__container">{icon}</div>
          <div
            className="NotificationBanner__container"
            style={{ flexBasis: '100%' }}
          >
            <header className="NotificationBanner__header">{title}</header>
            <p>{text}</p>
          </div>
          <div className="NotificationBanner__container NotificationBanner__container--close">
            <Button onClick={() => onClose()}>
              <Icon variant={IconVariant.Close} />
            </Button>
          </div>
        </div>
      </CSSTransition>
    )}
  </TransitionGroup>
);
