import { GameConfig } from '@/config/game';
import { getGameById } from '@/helpers/getGameById';
import {
  JackpotData,
  NextJackpot,
  getNextJackpot,
} from '@/helpers/getNextJackpot';
import { isJackpotExpired } from '@/helpers/isJackpotExpired';
import withCancelablePromise from '@/utilities/withCancelablePromise';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type GameTeaserProps<P> = P & {
  game: number | string;
  jackpotData?: JackpotData;
};

interface GameTeaserState {
  jackpot: string;
  jackpotClass1: number;
  jackpotClass2: number;
  subtitle: string;
  expire: number;
}

export const useGameTeaser = ({
  game,
  jackpotData,
}: {
  game: number | string;
  jackpotData?: JackpotData;
}) => {
  const config = getGameById(game);
  const nextJackpot =
    jackpotData && config ? jackpotData[config.id] || null : null;
  const initialState = useMemo(
    () =>
      nextJackpot && config
        ? {
            jackpot: nextJackpot.label,
            jackpotClass1: nextJackpot.valueClass1,
            jackpotClass2: nextJackpot.valueClass2,
            subtitle: getJackpotSubtitle(nextJackpot, config),
            expire: nextJackpot.expire || 0,
          }
        : {
            jackpot: config ? config.jackpotDesc : '',
            jackpotClass1: 0,
            jackpotClass2: 0,
            subtitle: config ? config.jackpotTimes : '',
            expire: 0,
          },
    [config, nextJackpot]
  );

  // @ts-ignore
  const theme = config ? config.shortname : 'default';

  const [state, setState] = useState(initialState);

  const getJackpot = useCallback(async (): Promise<GameTeaserState> => {
    if (config) {
      try {
        const nextJackpot = jackpotData
          ? jackpotData[config.id]
          : await getNextJackpot(config);
        return nextJackpot !== null
          ? {
              jackpot: nextJackpot.label,
              jackpotClass1: nextJackpot.valueClass1,
              jackpotClass2: nextJackpot.valueClass2,
              subtitle: getJackpotSubtitle(nextJackpot, config),
              expire: nextJackpot.expire,
            }
          : initialState;
      } catch (e) {
        return initialState;
      }
    }
    return initialState;
  }, [config, initialState, jackpotData]);

  const withOfflineState = useCallback(
    (state: GameTeaserState): GameTeaserState => {
      return (
        (config &&
          config.jackpotOfflineDesc &&
          isJackpotExpired(state) && {
            ...state,
            ...{
              jackpot: config.jackpotOfflineDesc,
              subtitle: 'Jackpot',
            },
          }) ||
        state
      );
    },
    [config]
  );

  useEffect(() => {
    let fetchJackPot;
    let isMounted = true;

    (async () => {
      try {
        fetchJackPot = withCancelablePromise<GameTeaserState>(getJackpot());

        const data = await fetchJackPot.promise;
        if (!isMounted) {
          return;
        }
        setState(() => withOfflineState(data));
      } catch (error) {
        if (!isMounted) {
          return;
        }
        if (error && error.isCanceled) {
          return;
        }
        setState(() => withOfflineState(initialState));
      }
    })();
    return () => {
      isMounted = false;
      if (fetchJackPot) {
        fetchJackPot.cancel();
      }
    };
  }, [getJackpot, initialState, withOfflineState]);

  return { state, config, theme };
};

function getJackpotSubtitle(
  nextJackpot: NextJackpot,
  config: GameConfig
): string {
  return Number(nextJackpot.value) > 0
    ? `${nextJackpot.prefix} ${nextJackpot.day} im Jackpot`
    : config.jackpotTimes;
}
