import Navigation from '@/components/Navigation';
import classnames from 'classnames';

export const HeaderNavigation = ({
  expanded,
  currentNavigationItem,
  onClose,
}: {
  expanded: boolean;
  currentNavigationItem: string | null;
  onClose: () => void;
}) => {
  return (
    <div
      className={classnames('HeaderNavigation', {
        'HeaderNavigation--expanded': expanded,
      })}
    >
      <div className="HeaderNavigation__close" onClick={onClose} />
      <div className="HeaderNavigation__container">
        <Navigation onClose={onClose} initialItem={currentNavigationItem} />
      </div>
    </div>
  );
};

export default HeaderNavigation;
