import { DrawDeadlineConfig, GameConfig } from '@/config/game';
import apiRequest from '@/helpers/apiRequest';
import { fromTimestamp, getNow } from '@/utilities/dateUtils';
import formatMillionCurrency from '@/utilities/formatMillionCurrency';

interface ApiData {
  jackpot?: number | null;
  jackpotInMio?: number;
  jackpotInEurFormatted?: string;
  datum: number;
  [key: string]: any;
}
interface ApiDataClass2New {
  jackpotNew?: number | null;
  jackpotClass2New?: number | null;
}

interface NextDrawDay {
  prefix: string;
  day: string;
  time: string;
  expire: number;
}

export interface NextJackpot extends NextDrawDay {
  id: number;
  value: number | string;
  valueClass1: number;
  valueClass2: number;
  label: string;
  labelGame: string;
}

export interface JackpotData {
  [game: string]: NextJackpot | null;
}

function formatDataInEuro(value: number): string {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  }).format(value);
}

export async function getNextJackpot(
  game: GameConfig
): Promise<NextJackpot | null> {
  const now = getNow();

  if (!game.drawDeadlines || game.drawDeadlines.length === 0) {
    return null;
  }

  const nextDrawDay = getNextDrawDay(game.drawDeadlines, now);
  const nextJackpot: NextJackpot = {
    id: game.id,
    prefix: nextDrawDay.prefix,
    day: nextDrawDay.day,
    time: nextDrawDay.time,
    value: 0,
    valueClass1: 0,
    valueClass2: 0,
    label: game.jackpotDesc,
    labelGame: game.jackpotDescGame || game.jackpotDesc,
    expire: nextDrawDay.expire,
  };

  if (game.jackpotSrc) {
    try {
      // Sonderfall Toto!
      let data: ApiData | null = await apiRequest(game.jackpotSrc);

      // Toto
      if (data !== null && (game.id === 8 || game.id === 9)) {
        // Deadline
        let jackpotEndpoint = game.jackpotSrc + '/' + now.year() + `${now.week()}`.padStart(2, '0');
        const jdata: ApiData | null = await apiRequest(jackpotEndpoint);
        if (jdata != null) data.deadline = jdata.deadline;
      }

      if (
        data !== null &&
        (data.jackpot || data.jackpotInMio || data.jackpotInEurFormatted) &&
        apiIsUpToDate(data, game.drawDeadlines)
      ) {
        const value = data.jackpot || data.jackpotInMio || 0;
        nextJackpot.label =
          value === 0 && data.jackpotInEurFormatted
            ? `${data.jackpotInEurFormatted} €`
            : game.jackpotIsEurUnFormatted
            ? formatDataInEuro(value)
            : formatMillionCurrency(value);
        nextJackpot.labelGame = nextJackpot.label;
        nextJackpot.value = value;
        nextJackpot.valueClass1 = data.jackpot || 0;

        if (game.jackpotLast) {
          const dataClass2New: ApiDataClass2New | null = await apiRequest(
            game.jackpotLast
          );
          if (dataClass2New != null && dataClass2New.jackpotClass2New != null) {
            const valueClass2 = dataClass2New.jackpotClass2New || 0;
            nextJackpot.valueClass2 = valueClass2;
            if (dataClass2New != null && dataClass2New.jackpotNew != null) {
              const valueClass1 = dataClass2New.jackpotNew || 0;
              nextJackpot.valueClass1 = valueClass1;
            }
          }
        }
      }
    } catch (error) {
      throw error;
    }
  }
  return nextJackpot;
}

export function getNextDrawDay(
  deadlines: readonly DrawDeadlineConfig[],
  now = getNow()
): NextDrawDay {
  const available = deadlines
    .map((item) => getDateFromDayAndTime(item.day, item.time, now))
    .sort((a, b) => a.unix() - b.unix());
  const nextThisWeek = available.find(
    (item) => item.isSameOrAfter(now) && item.isoWeek() === now.isoWeek()
  );
  const nextDrawDay = nextThisWeek || available[0];
  return {
    prefix: nextThisWeek ? 'Diesen' : 'Nächsten',
    day: nextDrawDay.format('dddd'),
    time: nextDrawDay.format('HH:mm'),
    expire: nextDrawDay.unix(),
  };
}

function getLastDrawDay(
  deadlines: readonly DrawDeadlineConfig[],
  now = getNow()
) {
  const available = deadlines
    .map((item) => getDateFromDayAndTime(item.day, item.time, now))
    .map((item) => (item.isSameOrAfter(now) ? item.subtract(1, 'week') : item))
    .sort((a, b) => b.unix() - a.unix());
  return available[0];
}

function apiIsUpToDate(
  data: ApiData,
  deadlines: readonly DrawDeadlineConfig[]
): boolean {
  const lastDraw = getLastDrawDay(deadlines);
  if (data.from && data.until) {
    // Toto !?
    return true;
  }
  const dateDraw = fromTimestamp(data.datum);
  return lastDraw.isSame(dateDraw, 'day');
}

const toDayNumber = (dayString) => {
  switch (dayString) {
    case 'Montag':
      return 1;
    case 'Dienstag':
      return 2;
    case 'Mittwoch':
      return 3;
    case 'Donnerstag':
      return 4;
    case 'Freitag':
      return 5;
    case 'Samstag':
      return 6;
    case 'Sonntag':
      return 7;
    default:
      throw new Error(`unknown dayString ${dayString}`);
  }
};

function getDateFromDayAndTime(
  day: string,
  time: string,
  now: moment.Moment
): moment.Moment {
  const dayNumber = toDayNumber(day);
  let date = now.clone();
  const [hours, minutes] = time.split(':').map((str) => parseInt(str));
  date = date.hours(hours);
  date = date.minutes(minutes);
  date = date.seconds(0);
  if (now.isoWeekday() <= dayNumber) {
    date = date.isoWeekday(dayNumber);
    if (date.unix() <= now.unix()) {
      date = date.add(1, 'week').isoWeekday(dayNumber);
    }
  } else {
    date = date.add(1, 'week').isoWeekday(dayNumber);
  }
  return date;
}
