import classnames from 'classnames';

export enum IconVariant {
  ArrowLeft = 'icon-arrow-left',
  ArrowRight = 'icon-arrow-right',
  ArrowDown = 'icon-arrow-down',
  Badge = 'icon-badge',
  BarChart = 'icon-barchart',
  Basket = 'icon-basket',
  Check = 'icon-check',
  Checkmark = 'icon-checkmark',
  ChevronDown = 'icon-chevron-right--rotate-cw',
  ChevronLeft = 'icon-chevron-right--rotate-flip',
  ChevronRight = 'icon-chevron-right',
  ChevronUp = 'icon-chevron-right--rotate-ccw',
  Clock = 'icon-clock',
  Close = 'icon-close',
  Contract = 'icon-contract',
  Cross = 'icon-cross',
  Diamond = 'icon-diamond',
  Download = 'icon-download',
  Edit = 'icon-edit',
  Email = 'icon-email',
  Fax = 'icon-fax',
  Filter = 'icon-filter',
  Football = 'icon-football',
  Goblet = 'icon-goblet',
  Goldbars = 'icon-goldbars',
  Home = 'icon-home',
  Hotline = 'icon-hotline',
  Info = 'icon-info',
  BigInfo = 'icon-big-info',
  Lightbulb = 'icon-lightbulb',
  Login = 'icon-login',
  Lotto = 'icon-lotto',
  Megaphone = 'icon-megaphone',
  Minus = 'icon-minus',
  Moneybag = 'icon-moneybag',
  Percent = 'icon-percent',
  Phone = 'icon-phone',
  Play = 'icon-play',
  Plus = 'icon-plus',
  Questions = 'icon-questions',
  QuickTips = 'icon-quick_tips',
  Random = 'icon-random',
  Shuffle = 'icon-shuffle',
  Stats = 'icon-stats',
  Tiles = 'icon-tiles',
  Trash = 'icon-trash',
  TV = 'icon-tv',
  Website = 'icon-website',
  Whistle = 'icon-whistle',

  External = 'icon-external',
  Offline = 'icon-offline',
  Push = 'icon-push',

  BigCalendar = 'icon-big-calendar',
  BigCourt = 'icon-big-court',
  BigDraw = 'icon-big-draw',
  BigMoney = 'icon-big-money',
  BigPayoff = 'icon-big-payoff',
  BigShield = 'icon-big-shield',
  BigTv = 'icon-big-Tv',

  Facebook = 'icon-facebook',
  Whatsapp = 'icon-whatsapp',
  YouTube = 'icon-youtube',
  Twitter = 'icon-x',

  Game = 'icon-game',
}

export enum IconSize {
  ExtraSmall = 'Icon--xs',
  Small = 'Icon--sm',
  Large = 'Icon--lg',
  ExtraLarge = 'Icon--xl',
}

export const Icon = ({
  variant,
  size,
  className,
}: {
  variant: IconVariant;
  size?: IconSize;
  className?: string;
}) => {
  const [name, modifier] = variant.split('--');

  return (
    <i
      className={classnames('Icon', size, className, {
        [`Icon--${modifier}`]: typeof modifier === 'string',
      })}
      data-variant={name}
    />
  );
};
