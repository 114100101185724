import { ButtonVariant } from '@/components/ButtonVariant';
import { LogoVariant } from '@/components/LogoVariant';
import { ID_RUBBELLOSE } from './ids';
import { GameConfig } from './index';

export const rubbellose: GameConfig = {
  id: ID_RUBBELLOSE,
  shortname: 'rubbellose',
  title: 'Rubbellose',
  imgPath: 'logo-rubbellose.svg',
  logo: LogoVariant.Rubbellose,
  button: ButtonVariant.Rubbellose,
  buttonSecondary: ButtonVariant.Secondary,
  drawDeadlines: [],
  jackpotDesc: 'Das Glück für zwischendurch',
  steps: [],
  urls: { stats: '', game: '/rubbellose/informationen' },
  jackpotTimes: '',
  chanceDesc: 'Ab 18 J.',
};
