import { Icon, IconSize, IconVariant } from '@/components/Icon';
import classnames from 'classnames';
import React from 'react';

export interface ModalProps {
  id: string;
  variant?: ModalVariant;
  wide?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

interface ModalState {
  isOpen: boolean;
  isHidden: boolean;
}

export enum ModalVariant {
  Default = 'Modal--default',
  LightBox = 'Modal--lightbox',
  Iframe = 'Modal--iframe',
}

class Modal extends React.Component<ModalProps, ModalState> {
  static defaultProps: Partial<ModalProps> = {
    variant: ModalVariant.Default,
  };

  static timeout = 300;
  static list: any[] = [];
  element: HTMLDivElement | null;

  static open = (id) => {
    const currentElement = Modal.getModalFromList(id);
    (window as any).lastScrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (!currentElement) {
      console.error(`currentElement with id: ${id} in Modal is not found`);
      return;
    }

    currentElement.setState({ isHidden: false }, () => {
      setTimeout(() => {
        document.body.classList.add('modal-open');
        document.body.style.top = `${-(window as any).lastScrollPosition}px`;
        currentElement.setState({ isOpen: true });
      }, 50);
    });
  };

  static close = (id) => {
    document.body.classList.remove('modal-open');
    document.body.style.top = '';
    Modal.getModalFromList(id).setState({ isOpen: false });
    const lastScrollPosition = (window as any).lastScrollPosition || 0;
    window.scrollTo(0, lastScrollPosition);

    setTimeout(() => {
      if (Modal.getModalFromList(id)) {
        Modal.getModalFromList(id).setState({ isHidden: true });
      }
    }, Modal.timeout);
  };

  static getModalFromList = (id) =>
    Modal.list.find((_modal) => _modal.props.id === id);

  constructor(props: ModalProps, state: ModalState) {
    super(props, state);
    this.state = {
      isOpen: false,
      isHidden: true,
    };
  }

  handleClick = ($event) => {
    if ($event.target.classList.contains('Modal')) {
      $event.preventDefault();
      Modal.close(this.props.id);
    }
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  componentDidMount() {
    if (this.element) {
      const root = document.getElementById('__next') as HTMLElement;
      root.appendChild(this.element);
      Modal.list.push(this);
    }
  }

  componentWillUnmount() {
    if (this.element) {
      Modal.list = Modal.list.filter(
        (modal) => this.props.id !== modal.props.id
      );
      this.element.remove();
    }
  }

  render() {
    const modalClassName = classnames(
      'Modal',
      this.props.id,
      {
        'Modal--is-open': this.state.isOpen,
        'Modal--is-hidden': this.state.isHidden,
        'Modal--wide': this.props.wide,
      },
      this.props.variant
    );

    return (
      <div
        className={modalClassName}
        onClick={this.handleClick}
        ref={(el) => (this.element = el)}
      >
        {(this.props.variant === ModalVariant.LightBox ||
          this.props.variant === ModalVariant.Iframe) && (
          <div
            className="Modal__lightbox-close"
            onClick={() => {
              Modal.close(this.props.id);
              this.handleClose();
            }}
          >
            <Icon variant={IconVariant.Close} size={IconSize.Large} />
          </div>
        )}

        {!this.state.isHidden && (
          <div className="Modal__body">
            {React.Children.map(this.props.children, (child: JSX.Element) => {
              return React.cloneElement(child, {
                modal_id: this.props.id,
              });
            })}
          </div>
        )}
      </div>
    );
  }
}

export default Modal;

interface ModalHeaderProps {
  modal_id?: string;
  quitAction?: () => void;
}

export const ModalHeader: React.FC<
  React.PropsWithChildren<ModalHeaderProps>
> = (props) => (
  <div
    className="Modal__header"
    onClick={() => {
      Modal.close(props.modal_id);
      if (props.quitAction !== undefined) {
        props.quitAction();
      }
    }}
  >
    {props.children}
    <div className="Modal__header-close" />
  </div>
);

export const ModalContent: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => <div className="Modal__content">{props.children}</div>;
