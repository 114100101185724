import classnames from 'classnames';

export enum LoadingIconVariant {
  Default = 'default',
  Lotto = 'lotto',
}

export const LoadingIcon = ({
  isLoading,
  color = LoadingIconVariant.Default,
}: {
  isLoading: boolean;
  color?: LoadingIconVariant;
}) => (
  <svg
    viewBox="0 0 24 24"
    overflow="visible"
    preserveAspectRatio="xMidYMid meet"
    className={classnames('LoadingIcon a-loading-btn__spinner', {
      'LoadingIcon--isLoading': isLoading,
      [`LoadingIcon--${color}`]: color,
    })}
  >
    <g
      style={{
        transform: 'scale(0.9) translate(1px, 1px)',
        transformOrigin: 'top left',
      }}
    >
      <path
        className="LoadingIcon__upperRight"
        d="m14.966 10.904c1.3048-2.5908 3.1911-5.0248 5.4274-6.3191 1.4446-0.83585 3.0395-0.43742 3.4417 1.5976 0.30564 1.5476 0.26041 2.7323-0.77071 3.4158-1.7466 1.1581-4.881 1.5296-8.0987 1.3053l2.284e-4 2.307e-4z"
      />
      <path
        className="LoadingIcon__upperLeft"
        d="m13.981 10.817c-3.6731-0.3922-7.2813-1.5351-8.8904-3.1468-0.75153-0.75372-0.4781-1.7518-0.028782-2.4663 1.6234-2.5805 5.8882-4.7952 9.3836-5.1782 1.1193-0.12227 1.8281 0.16242 1.8836 1.429 0.11284 2.5728-0.63411 6.0983-2.3482 9.3628l2.285e-4 -4.615e-4z"
      />
      <path
        className="LoadingIcon__lowerLeft"
        d="m13.544 11.606c-2.0976 3.6032-5.4206 6.756-10.121 7.7513-2.6137 0.55277-3.431-1.297-3.4234-3.3291 0.0091371-2.2978 0.50528-4.1751 4.0098-4.6963 2.2039-0.32807 6.0442-0.21825 9.5346 0.27408z"
      />
      <path
        className="LoadingIcon__lowerRight"
        d="m14.558 11.761c2.7969 0.46557 5.226 1.1858 6.1835 2.1285 0.68414 0.67366 0.62224 1.3164 0.10279 2.0572-1.2888 1.8373-4.5937 3.288-6.5275 3.4998-1.0046 0.11074-1.2374-0.47364-1.2831-1.1535-0.11513-1.7188 0.4516-4.1458 1.5243-6.5322v2.308e-4z"
      />
      <path
        className="LoadingIcon__stem"
        d="m12.626 14.963c-1.5631 3.8057-3.9072 8.4132-5.6997 9.0368l-1.0668-1.1305c2.531-0.17003 5.172-5.6177 5.9608-7.3769l0.80589-0.52947h-2.285e-4z"
      />
    </g>
  </svg>
);
