import {
  CmsNavigationGroupComponent,
  CmsNavigationLinkComponent,
  CmsNavigationRootComponent,
} from '@/components/CmsInterfaces';
import { LogoVariant } from '@/components/LogoVariant';
import NavigationGameTeaser from '@/components/NavigationGameTeaser';
import { NavigationList } from '@/components/NavigationList';
import { NavigationTitle } from '@/components/NavigationTitle';
import { getGameById } from '@/helpers/getGameById';
import classnames from 'classnames';
import React from 'react';

export interface NavigationLevelProps {
  id: string;
  isActive: boolean;
  isCurrent: boolean;
  index: number;
  title: string;
  themeId?: string;
  gameLink?: string;
  overviewLink?: string;
  currentItems: string[];
  onClick: (item: string, level: number) => void;
  onClose: () => void;
  items: Array<
    | CmsNavigationRootComponent
    | CmsNavigationGroupComponent
    | CmsNavigationLinkComponent
  >;
}

export class NavigationLevel extends React.Component<NavigationLevelProps, {}> {
  private get level(): string {
    return this.props.index > 0 ? this.props.index.toString() : this.props.id;
  }

  private get gameId(): number {
    return this.props.themeId ? +this.props.themeId : 0;
  }

  private get logo(): LogoVariant | null {
    const game = getGameById(this.gameId);
    return game
      ? game.alternativeDesign?.productLogoNavigation
        ? game.alternativeDesign.productLogoNavigation
        : game.logo
      : null;
  }

  render(): JSX.Element {
    return (
      <section
        className={classnames(
          'NavigationLevel',
          `NavigationLevel--${this.level}`,
          {
            'NavigationLevel--current': this.props.isCurrent,
            'NavigationLevel--active': this.props.isActive,
          }
        )}
      >
        <nav className="NavigationLevel__list">
          <NavigationTitle
            title={this.props.title}
            index={this.props.index}
            gameId={this.gameId}
            logo={this.logo}
            overview={this.props.overviewLink}
            onClose={this.props.onClose}
          />
          {this.gameId !== 0 && this.props.index === 1 && (
            <NavigationGameTeaser
              link={this.props?.gameLink || '#'}
              game={this.gameId}
            />
          )}
          <NavigationList
            items={this.props.items}
            currentItems={this.props.currentItems}
            level={this.props.index}
            onClick={this.props.onClick}
            onClose={this.props.onClose}
          />
        </nav>
      </section>
    );
  }
}
